// Select

.css-1dimb5e-singleValue {
  font-size: 12px !important;
}

.css-1jqq78o-placeholder {
  font-size: 12px !important;
  color: gray !important;
}

.dropdown-heading-value {
  font-size: 12px !important;
  color: $BUTTON_BLUE !important;
  padding: 5px 0px !important;

  span {
    background-color: #ebf2ff;
    border-radius: 2rem !important;
    padding: 1px 10px !important;
    font-weight: 500;
  }
}

.search-wrapper,
.searchWrapper {
  background-color: white;

  span {
    background-color: #ebf2ff;
    color: $BUTTON_BLUE !important;
    padding: 0 8px;
    font-size: 0.6rem;
    font-weight: 600;

    img {
      background-color: $BUTTON_BLUE;
      content: "x";
    }
  }

  input {
    padding: 0.2rem;
  }
}

.cSelect {
  &__label {
    color: gray;
    font-weight: 600;
    font-size: 0.7rem;
    margin-bottom: 0.3rem;
    margin-left: 0.2rem;
  }

  &__closeIcon {
    font-style: normal;
    margin-left: 0.3rem;
    display: flex;
    align-items: center;

    &__icon {
      font-size: 0.7rem !important;
      font-weight: bolder !important;
    }
  }
}


// Slider

.sliderLabel {
  label {
    font-size: 0.7rem;
    color: gray;
    font-weight: 600;
  }
}

.css-14pt78w-MuiSlider-rail,
.MuiSlider-rail {
  color: black !important;
  height: 2px !important;
}

.css-1gv0vcd-MuiSlider-track {
  color: unset !important;
  height: 0px !important;
  color: $BUTTON_BLUE !important;
}

.css-eg0mwd-MuiSlider-thumb {
  height: 15px !important;
  width: 15px !important;
  color: $BUTTON_BLUE !important;
}

// Chekboxes

.css-ahj2mt-MuiTypography-root {
  font-size: 12px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

$primary-color: #6200ea; // Change to your preferred color
$secondary-color: #f3f4f6; // Change to your preferred secondary color
$hover-color: darken($primary-color, 10%); // Darker shade for hover effects

._form-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: $secondary-color;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
  }
}

._form-3d {
  display: flex;
  flex-direction: column;
}

._form-group {
  margin-bottom: 20px;

  .MuiTextField-root {
    width: 100%;
    background-color: white;
    border-radius: 4px;
    transition: border-color 0.3s;

    &:focus-within {
      border-color: $primary-color;
    }
  }
}

._form-group-email {
  margin-bottom: 20px;
}

._button-container {
  display: flex;
  justify-content: center;
}

._form-button {
  background-color: $primary-color;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: $hover-color;
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }
}

.alert-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  animation: slideIn 0.5s ease-out, slideOut 0.5s ease-in 3s forwards;
}

@keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }
  

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Staatliches&display=swap');

html {
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

*:not(i),
*::after:not(i),
*::before:not(i) {
  font-family: "Montserrat", sans-serif !important;
}

body {
  box-sizing: border-box;
  background-color: #F0F8FF;
}



body Button {
  font-family: "Montserrat", sans-serif !important;
}

._navbar {
  background-color: white !important;
  color: black !important;
  box-shadow: unset !important;
  padding: .5rem 0rem !important;
  position: fixed !important;
  z-index: 1 !important;
  top: 0 !important;
}

._navbar p {
  font-size: 1.5rem;
  letter-spacing: -1px;
  font-weight: 500;
  font-family: "Inter" !important;
}

._navbar__linksBox {
  display: flex;
  align-items: center;
  font-size: .9rem;
}

._navbar__linksBox a {
  color: black !important;
  text-decoration: unset;
  margin-right: 2rem;
  margin-top: auto;
  // font-size: 1.1rem;
}

._navbar__linksBox Button {
  text-transform: capitalize;
  background-color: #3380FF !important;
  font-size: .9rem !important;
}

._navbar__searchBox {
  border: 1px solid lightgray;
  border-radius: 1.5rem !important;
}

._menuLinks a {
  color: black !important;
  text-decoration: unset;
  margin-right: 2rem;
}

._css-hyum1k-MuiToolbar-root {
  padding: 1rem !important;
}

._css-96uuyl {
  margin-right: 0 !important;
  margin-left: 24px !important;
}

._navbarSearchFilter {
  background-color: white;
  border-radius: .8rem;
  box-shadow: 0 0.5rem 1.5rem #d3d3d3;
  padding-top: 1rem;
  margin: 7rem 2rem -6rem 2rem;
}

@media (max-width: 768px) {
  ._navbarSearchFilter {
    margin: 7rem 1rem -6rem 1rem;
  }
}

._css-1dimb5e-singleValue {
  font-size: 12px !important;
}

._css-1jqq78o-placeholder {
  font-size: 12px !important;
  color: gray !important;
}

._dropdown-heading-value {
  font-size: 12px !important;
  color: #3380FF !important;
  padding: 5px 0px !important;
}

._dropdown-heading-value span {
  background-color: #ebf2ff;
  border-radius: 2rem !important;
  padding: 1px 10px !important;
  font-weight: 500;
}

._search-wrapper,
._searchWrapper {
  background-color: white;
}

._search-wrapper span,
._searchWrapper span {
  background-color: #ebf2ff;
  color: #3380FF !important;
  padding: 0 8px;
  font-size: 0.6rem;
  font-weight: 600;
}

._search-wrapper span img,
._searchWrapper span img {
  background-color: #3380FF;
  content: "x";
}

._search-wrapper input,
._searchWrapper input {
  padding: 0.2rem;
}

._cSelect__label {
  color: gray;
  font-weight: 600;
  font-size: 0.7rem;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
}

._cSelect__closeIcon {
  font-style: normal;
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
}

._cSelect__closeIcon__icon {
  font-size: 0.7rem !important;
  font-weight: bolder !important;
}

._sliderLabel label {
  font-size: 0.7rem;
  color: gray;
  font-weight: 600;
}

._css-14pt78w-MuiSlider-rail,
._MuiSlider-rail {
  color: black !important;
  height: 2px !important;
}

._css-1gv0vcd-MuiSlider-track {
  color: unset !important;
  height: 0px !important;
  color: #3380FF !important;
}

._css-eg0mwd-MuiSlider-thumb {
  height: 15px !important;
  width: 15px !important;
  color: #3380FF !important;
}

._css-ahj2mt-MuiTypography-root {
  font-size: 12px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  appearance: textfield;
  /* Firefox */
}

._sidebar {
  background-color: white;
  border-radius: .8rem;
  box-shadow: 0 0.5rem 1.5rem #d3d3d3;
  padding: 1rem;
  width: 180px;
  min-height: 76vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
}

@media (max-width: 768px) {
  ._sidebar {
    height: 100%;
    min-height: 100vh;
    position: unset;
  }
}

._sidebar__links {
  display: flex;
  flex-direction: column;
}

._sidebar__links__link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: .3rem 0;
  border-radius: 5px;
  padding: .7rem .8rem;
  background-color: #F5F9FF;
  white-space: nowrap;
  color: black;
  text-decoration: unset;
  font-weight: 600;
  font-size: .7rem;
  white-space: nowrap;
  border: 1px solid transparent;
}

._sidebar__links__link__icon {
  color: #3380FF;
  font-size: 1rem !important;
  margin-right: .5rem;
}

._sidebar-button-clicked {
  border: 1px solid #3380FF !important;
  background-color: #D6E6FF !important;
}

._logoutModal__container {
  outline: none !important;
  border-radius: .8rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

._logoutModal__container__head {
  font-weight: 600;
  font-size: .9rem;
}

._logoutModal__container__iconBox {
  margin-top: 2rem;
}

._logoutModal__container__iconBox__icon {
  font-size: 5rem !important;
}

._logoutModal__container__logoutMessage {
  margin-top: 2rem;
  font-weight: 600;
}

._logoutModal__container__buttonsBox {
  margin-top: 3rem;
}

._logoutModal__container__buttonsBox__cancel {
  text-transform: capitalize !important;
  border-color: #3380FF !important;
  color: #3380FF !important;
  margin-right: 1rem !important;
}

._logoutModal__container__buttonsBox__logout {
  text-transform: capitalize !important;
  background-color: #3380FF !important;
}

._projects {
  padding: 7rem 2rem 2rem 2rem;
}

@media (max-width: 768px) {
  ._projects {
    padding: 7rem 1rem 1rem 1rem;
  }
}

._projects__header {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

@media (max-width: 950px) {
  ._projects__header {
    display: flex;
    flex-direction: column;
  }
}

._projects__header__left {
  display: flex;
  flex-direction: column;
}

._projects__header__left p {
  color: gray;
  font-size: 0.9rem;
}

._projects__header__left h3 {
  margin-top: 0.2rem;
}

._projects__header__right {
  margin-top: 0.2rem;
}

@media (max-width: 950px) {
  ._projects__header__right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

._projects__header__right__skillerButton {
  margin-right: 1rem !important;
  display: none !important;
}

@media (max-width: 900px) {
  ._projects__header__right__skillerButton {
    display: block !important;
  }
}

._projects__header__right__filterButton {
  text-transform: capitalize !important;
  background-color: #3380FF !important;
}

._projects__header__right__filterButton__icon {
  font-size: 1rem !important;
}




._projects__body {
  margin-top: 1rem;
}

._projects__body__filterTabs {
  border-radius: 0.8rem;
  margin-bottom: 1rem;
  background-color: white;
  box-shadow: 0 0.5rem 1.5rem #d3d3d3;
}

._projects__body__container {
  display: flex;
  align-items: flex-start;
}



@media (max-width: 900px) {
  ._projects__body__container {
    display: flex;
    flex-direction: column;
  }
}

._projects__body__container__left {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  transition: width 0.5s;
}

._projects__body__container__left__card {
  // margin-bottom: 1rem;
  // padding: 3rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  // background-color: white;
  // box-shadow: 0 0.5rem 1.5rem #d3d3d3;
  margin-top: 10px;
}

._projects__body__container__left__card__avatar {
  margin-bottom: .5rem;
}

._projects__body__container__left__card__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._projects__body__container__left__card__top__iconsBox {
  display: flex;
  align-items: center;
}

._projects__body__container__left__card__top__iconsBox__write {
  border-radius: 7px !important;
  border: 1px solid rgba(94, 110, 120, 0.40) !important;
  margin-right: 0.5rem !important;
  height: 39px !important;
  padding: 11px 13px !important;

  color: #5E6E78 !important;
  font-family: Montserrat;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 17px;
  text-transform: initial !important;
  /* 121.429% */

}

._projects__body__container__left__card__top__iconsBox__write__icon {
  font-size: 0.8rem !important;
  margin-left: 0.2rem;
  color: gray !important;
  font-weight: 600 !important;
}

._projects__body__container__left__card__top__iconsBox__add {
  font-size: 14px !important;
  text-transform: capitalize !important;
  color: #3380FF !important;
  max-width: 119.954px;
  padding: 11px 13px;
  font-weight: 600 !important;
  border-radius: 7px !important;
  border: 1px solid rgba(51, 128, 255, 0.40) !important;
}

._projects__body__container__left__card__top__iconsBox__add__icon {
  /* font-size: 0.8rem !important; */
  margin-left: 0.2rem;
  /* color: rgba(51, 128, 255, 0.40) !important; */
  font-weight: 600 !important;
  width: 12px;
  height: 12px;
  fill: #3380FF !important;
}

._projects__body__container__left__card__top__iconsBox__edit {
  border: 1px solid #3380FF !important;
  font-size: 0.7rem !important;
  text-transform: capitalize !important;
  color: #3380FF !important;
  padding: 0.1rem 0rem !important;
  font-weight: 600 !important;
  margin-right: .5rem !important;
}

._projects__body__container__left__card__top__iconsBox__delete {
  border: 1px solid #3380FF !important;
  font-size: 0.7rem !important;
  text-transform: capitalize !important;
  color: red !important;
  padding: 0.1rem 0rem !important;
  font-weight: 600 !important;
  border: 1px solid red !important;
}

._projects__body__container__left__card__top__iconsBox__delete__deleteIcon {
  font-size: 0.8rem !important;
  margin-left: 0.2rem;
  color: red !important;
  font-weight: 600 !important;
}

._projects__body__container__left__card__body {
  // margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  justify-content: flex-start;
}

._projects__body__container__left__card__body__specs {
  margin-right: 10px;
  margin-bottom: 20px;
  // width: 120px; /* Set a fixed width */
  // max-width: 300px; /* Ensure the max width doesn't exceed this value */
  // overflow: hidden; 
  
}

._projects__body__container__left__card__body__specs__paired_values {

}

._projects__body__container__left__card__body__specs p {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;

}

._projects__body__container__left__card__body__specs__desc {
  // margin-top: 18px !important;
}

._projects__body__container__left__card__body__specs__desc span {
  background: rgba(94, 110, 120, 0.1);
  color: #5E6E78;
  border-radius: 5px;
  padding: 10px 8px;
  font-size: 0.7rem;
  font-weight: 600;
  margin-right: 0.3rem;

}

._projects__body__container__left__card__body__specs__desc small {
  border-radius: 1px;
  font-size: 0.7rem;
  font-weight: 600;
  color: #5E6E78;

}

/* my css */
._projects__body__container__left__card__body__specs__desc {
  display: grid;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}

._projects__body__container__left__card__body__specs__desc ._flag_design {
  background: rgba(94, 110, 120, 0.10);
  color: gray;
  margin-top: 0px;
  border-radius: 0.4rem;
}

/* my css */
._projects__body__container__left__card__body2 {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid lightgray;
  padding-top: 1rem;
  gap: 0px;
}

._projects__body__container__left__card__body2__specs {
  // margin-bottom: 10px;
  // align-items: center;
  // justify-content: space-around
}

._projects__body__container__left__card__body2__specs p {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;

}

._projects__body__container__left__card__body2__specs__desc {
  display: flex;
  margin-top: 4.8px;
  justify-content: space-evenly;
  // gap: 1px;
}

._projects__body__container__left__card__body2__specs__desc > * {
  margin-right: 5px; /* Adjust this value to reduce the spacing */
}

._projects__body__container__left__card__body2__specs__desc span {
  background: rgba(94, 110, 120, 0.1);
  color: #5E6E78;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 0.7rem;
  font-weight: 600;
  margin-right: 0 
}

._projects__body__container__left__card__bottom {
  border-top: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._projects__body__container__left__card__bottom__left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

._projects__body__container__left__card__bottom__left__tip {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 600;
  margin-right: 0.5rem;
  padding-top: 0.5rem;
}

._projects__body__container__left__card__bottom__left__tip__icon {
  color: #FFB849 !important;
  font-size: 1.2rem !important;
  margin-right: 0.3rem;
}

._projects__body__container__left__card__bottom__left__tip__iconAvatar {
  font-size: .7rem !important;
  margin-right: 0.3rem;
  width: 1.2rem !important;
  height: 1.2rem !important;
}

._projects__body__container__left__card__bottom__left__type {
  border-radius: 19px;
  background: #FFB849;
  border-radius: 19px;
  margin-right: 0.5rem;
  padding: 5px 8px;
  margin-top: 0.5rem;
  color: #FFF;
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 11px;
}

._projects__body__container__left__card__bottom__left__dot {
  font-size: 1rem;
  color: gray;
  font-weight: 600;
  margin-right: 0.5rem;
  margin-top: -0.5rem;
  padding-top: 0.5rem;
}

._projects__body__container__left__card__bottom__left__time {
  font-size: 0.7rem;
  color: gray;
  font-weight: 500;
  margin-right: 0.5rem;
  padding-top: 0.5rem;
}

._projects__body__container__left__card__bottom button {
  margin-top: 0.5rem;
  color: gray;
  border: 1px solid lightgray;
  font-size: 0.7rem;
  padding: 0px 3px;
  background-color: #e1edf7;
  border-radius: 5px;
  cursor: pointer;
}

._projects__body__container__left__card__bottom button:hover {
  background-color: #F0F8FF;
}

._projects__body__container__right {
  margin-left: 1rem;
  transition: width 0.5s;
}

@media (max-width: 900px) {
  ._projects__body__container__right {
    display: none;
    margin-left: unset;
    width: 100%;
  }
}

._projects__body__container__right__card {
  width: 250px;
  padding: 1rem;
  border-radius: 0.8rem;
  background-color: white;
  box-shadow: 0 0.5rem 1.5rem #d3d3d3;
}

@media (max-width: 900px) {
  ._projects__body__container__right__card {
    width: 100%;
    border-radius: unset;
  }
}

._projects__body__container__right__card__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

._projects__body__container__right__card__top__button {
  border: 1px solid #3380FF !important;
  font-size: 0.7rem !important;
  text-transform: capitalize !important;
  color: #3380FF !important;
  padding: 0.1rem 0.5rem !important;
  font-weight: 600 !important;
}

._projects__body__container__right__card__top__button__icon {
  font-size: 0.8rem !important;
  margin-left: 0.2rem;
  color: #3380FF !important;
  font-weight: 600 !important;
}

._projects__body__container__right__card__box {
  background-color: #F0F8FF;
  margin: 0.5rem 0;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

._projects__body__container__right__card__box__icon {
  color: #3380FF;
  margin-right: 0.5rem;
}

._projects__body__container__right__card__box p {
  font-size: 0.8rem;
}

._addProject {
  padding: 7rem 2rem 2rem 2rem;
  transition: padding .5s, padding-left .5s, padding-right .5s;
  padding-left: 20%;
  padding-right: 20%;
}

@media (max-width: 1250px) {
  ._addProject {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (max-width: 1000px) {
  ._addProject {
    padding-left: unset;
    padding-right: unset;
    padding: 7rem 1rem 1rem 1rem;
  }
}

._addProject__header__backIcon {
  margin-left: -0.5rem !important;
  color: black !important;
}

._addProject__container {
  margin-top: 3rem;
}

._addProject__container__name {
  padding: 0rem 1rem 1rem 1rem;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

._addProject__container__name p {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  position: absolute;
  margin-top: -0.5rem;
}

._addProject__container__name__textField {
  margin-top: 1rem;
}

._addProject__container__name__textField input {
  width: 100%;
  border: 1px solid lightgray;
  outline: unset;
  padding: .7rem .5rem;
  border-radius: 5px;
}

._addProject__container__areaExp {
  padding: 0rem 1rem 1rem 1rem;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
}

._addProject__container__areaExp p {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  position: absolute;
  margin-top: -0.5rem;
}

._addProject__container__profession__textField {
  margin-top: 1rem;
}

._addProject__container__profession {
  padding: 0rem 1rem 1rem 1rem;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  margin-top: 1rem
}

._addProject__container__profession p {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  position: absolute;
  margin-top: -0.5rem;
}

._addProject__container__profession__textField {
  margin-top: 1rem;
}

._addProject__container__totalExp {
  padding: 0 1rem 1rem 1rem;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

._addProject__container__totalExp__head {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  position: absolute;
  margin-top: -0.5rem;
}

._addProject__container__totalExp__main {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  ._projects__body__container__left__card {
    margin: 30px 20px;
  }

  ._addProject__container__totalExp__main {
    display: unset;
    justify-content: unset;
    align-items: unset;
  }

  ._question_hover:hover ._yellow_text {
    left: 0% !important;
    top: -27px !important;
  }

  ._question_hover:hover ._yellow_text1 {
    left: 0% !important;
  }

  ._question_hover ._yellow_text {
    left: 0% !important;
  }

  ._question_hover ._yellow_text1 {
    left: 0% !important;
  }

  // ._projects__body__container__left__card__body__specs__desc {
  //   margin-top: 10px !important;
  //   flex-wrap: wrap;
  // }
}

._addProject__container__totalExp__main__box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 950px) {
  ._addProject__container__totalExp__main__box {
    display: flex;
    justify-content: flex-start;
    margin-top: unset;
  }
}

@media (max-width: 768px) {
  ._addProject__container__totalExp__main__box {
    flex-wrap: wrap;
  }


}

._addProject__container__totalExp__main__box__total {
  width: 32%;
  margin-right: 2rem;
  margin-top: 0.3rem;
}

@media (max-width: 768px) {
  ._addProject__container__totalExp__main__box__total {
    width: 100%;
    margin-right: unset;
  }
}

._addProject__container__totalExp__main__box__years {
  width: 32%;
  margin-right: 2rem;
  margin-top: 0.3rem;
}

@media (max-width: 768px) {
  ._addProject__container__totalExp__main__box__years {
    width: 100%;
    margin-right: unset;
  }
}

._addProject__container__totalExp__main__box__period {
  width: 32%;
  margin-right: 2rem;
  margin-top: 0.3rem;
}

@media (max-width: 768px) {
  ._addProject__container__totalExp__main__box__period {
    width: 100%;
    margin-right: unset;
  }
}

._addProject__container__totalExp__main__box__productType {
  width: 230px;
  margin-top: 0.3rem;
}

@media (max-width: 950px) {
  ._addProject__container__totalExp__main__box__productType {
    width: 45%;
  }
}

@media (max-width: 768px) {
  ._addProject__container__totalExp__main__box__productType {
    width: 100%;
  }
}

._addProject__container__totalExp__main__box__removeButton {
  margin-top: 1.5rem;
  margin-left: .5rem;
}

@media (max-width: 950px) {
  ._addProject__container__totalExp__main__box__removeButton {
    width: 4%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media (max-width: 768px) {
  ._addProject__container__totalExp__main__box__removeButton {
    margin-top: 0;
    width: 100%;
    margin-right: unset;
  }
}

._addProject__container__totalExp__main__box__removeButton__button {
  font-size: .7rem;
  text-transform: capitalize !important;
  color: red;
  margin-top: .3rem;
}

._addProject__container__totalExp__main__box__removeButton__button__icon {
  color: red;
}

._addProject__container__totalExp__addButton {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

._addProject__container__totalExp__addButton__button {
  color: #3380FF !important;
  border: 1px solid #3380FF !important;
  border-radius: 1rem !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  width: 900px;
}

._addProject__container__totalExp__addButton__button__icon {
  font-size: 1.2rem !important;
  margin-right: 0.5rem;
}

._addProject__container__assets__textField {
  margin-top: 1rem;
}

._addProject__container__assets {
  padding: 0rem 1rem 1rem 1rem;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  margin-top: 1rem
}

._addProject__container__assets p {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  position: absolute;
  margin-top: -0.5rem;
}

._addProject__container__assets__textField {
  margin-top: 1rem;
}

._addProject__container__offeredGratification {
  margin-top: 3rem;
}

._addProject__container__offeredGratification__top {
  border-top: 1px solid lightgray;
}

._addProject__container__offeredGratification__top span {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  position: absolute;
  background-color: #F0F8FF;
  margin-top: -0.5rem;
  margin-left: 1rem;
}

._addProject__container__offeredGratification__body {
  margin: 2rem 1rem 0 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center
}

._addProject__container__offeredGratification__body__left {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 500px;
}

@media (max-width: 768px) {
  ._addProject__container__offeredGratification__body__left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}

._addProject__container__offeredGratification__body__left label {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  margin-right: 1rem;
}

@media (max-width: 768px) {
  ._addProject__container__offeredGratification__body__left label {
    margin-right: unset;
    margin-top: 0.5rem;
  }
}

._addProject__container__offeredGratification__body__left__input {
  margin-right: 1rem;
  width: 150px;
}

@media (max-width: 768px) {
  ._addProject__container__offeredGratification__body__left__input {
    margin-right: unset;
    width: 100%;
  }
}

._addProject__container__offeredGratification__body__left__input:nth-last-child(1) {
  margin-right: 0;
}

._addProject__container__wantedAvaiablility {
  margin-top: 3rem;
}

._addProject__container__wantedAvaiablility__top {
  border-top: 1px solid lightgray;
}

._addProject__container__wantedAvaiablility__top span {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  position: absolute;
  background-color: #F0F8FF;
  margin-top: -0.5rem;
  margin-left: 1rem;
}

._addProject__container__wantedAvaiablility__body {
  margin: 2rem 1rem 0 1rem;
  display: flex;
  justify-content: center;
}

._addProject__container__wantedAvaiablility__body__left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
}

@media (max-width: 950px) {
  ._addProject__container__wantedAvaiablility__body__left {
    width: 100%;
  }
}

@media (max-width: 768px) {
  ._addProject__container__wantedAvaiablility__body__left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}

._addProject__container__wantedAvaiablility__body__left label {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  margin-right: 1rem;
}

@media (max-width: 768px) {
  ._addProject__container__wantedAvaiablility__body__left label {
    margin-right: unset;
    margin-top: 0.5rem;
  }
}

._addProject__container__wantedAvaiablility__body__left__input {
  margin-right: 1rem;
  width: 120px;
}

@media (max-width: 768px) {
  ._addProject__container__wantedAvaiablility__body__left__input {
    margin-right: unset;
    width: 100%;
  }
}

._addProject__container__wantedAvaiablility__body__left__input:nth-last-child(1) {
  margin-right: 0;
}

._addProject__container__wantedAvaiablility__body__left__checkBoxes {
  display: flex;
  white-space: nowrap;
  align-items: center;
}

@media (max-width: 768px) {
  ._addProject__container__wantedAvaiablility__body__left__checkBoxes {
    margin-top: 1rem;
  }
}

._addProject__container__wantedAvaiablility__body__left__checkBoxes label {
  margin-right: 0;
}

._addProject__container__wantedAvaiablility__body__left__checkBoxes div {
  display: flex;
  align-items: center;
  margin-left: 3rem;
}

@media (max-width: 768px) {
  ._addProject__container__wantedAvaiablility__body__left__checkBoxes div {
    margin-top: 0.5rem;
    margin-left: 0;
  }

  ._addProject__container__wantedAvaiablility__body__left__checkBoxes div:nth-last-child(1) {
    margin-left: 3rem;
  }
}

._addProject__container__wantedAvaiablility__body__left__checkBoxes div input {
  margin-right: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
}

._addProject__container__wantedAvaiablility__body__left__checkBoxes div p {
  font-size: 0.7rem;
  font-weight: 600;
  color: gray;
}

._addProject__container__workingType {
  margin-top: 3rem;
}

._addProject__container__workingType__top {
  border-top: 1px solid lightgray;
}

._addProject__container__workingType__top span {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  position: absolute;
  background-color: #F0F8FF;
  margin-top: -0.5rem;
  margin-left: 1rem;
}

._addProject__container__workingType__body {
  margin: 2rem 1rem 0 1rem;
  display: flex;
  justify-content: center;
}

._addProject__container__workingType__body__left {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 900px;
}

@media (max-width: 950px) {
  ._addProject__container__workingType__body__left {
    width: 100%;
  }
}

@media (max-width: 768px) {
  ._addProject__container__workingType__body__left {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

._addProject__container__workingType__body__left label {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  margin-right: 1rem;
}

@media (max-width: 768px) {
  ._addProject__container__workingType__body__left label {
    margin-right: unset;
    margin-top: 0.5rem;
  }
}

._addProject__container__workingType__body__left__input {
  margin-right: 1rem;
  width: 120px;
}

@media (max-width: 768px) {
  ._addProject__container__workingType__body__left__input {
    margin-right: unset;
    width: 100%;
  }
}

._addProject__container__workingType__body__left__input:nth-last-child(1) {
  margin-right: 0;
}

._addProject__container__workingType__body__left__checkBoxes {
  display: flex;
  white-space: nowrap;
  align-items: center;
}

@media (max-width: 768px) {
  ._addProject__container__workingType__body__left__checkBoxes {
    margin-top: 1rem;
  }
}

._addProject__container__workingType__body__left__checkBoxes label {
  margin-right: 0;
}

._addProject__container__workingType__body__left__checkBoxes div {
  display: flex;
  align-items: center;
  margin-left: 3rem;
}

@media (max-width: 768px) {
  ._addProject__container__workingType__body__left__checkBoxes div {
    margin-top: 0.5rem;
    margin-left: 0;
  }

  ._addProject__container__workingType__body__left__checkBoxes div:nth-last-child(1) {
    margin-left: 3rem;
  }
}

._addProject__container__workingType__body__left__checkBoxes div input {
  margin-right: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
}

._addProject__container__workingType__body__left__checkBoxes div p {
  font-size: 0.7rem;
  font-weight: 600;
  color: gray;
}

._addProject__container__projectDetails {
  margin-top: 3rem;
}

._addProject__container__projectDetails__top {
  border-top: 1px solid lightgray;
}

._addProject__container__projectDetails__top span {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  position: absolute;
  background-color: #F0F8FF;
  margin-top: -0.5rem;
  margin-left: 1rem;
}

._addProject__container__projectDetails__body {
  margin: 2rem 1rem 0 1rem;
  display: flex;
  justify-content: center;
}

._addProject__container__projectDetails__body__box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 900px;
}

@media (max-width: 950px) {
  ._addProject__container__projectDetails__body__box {
    width: 100%;
  }
}

@media (max-width: 768px) {
  ._addProject__container__projectDetails__body__box {
    display: flex;
    flex-direction: column;
  }
}

._addProject__container__projectDetails__body__box__left {
  display: flex;
  flex-direction: column;
  width: 48%;
}

@media (max-width: 768px) {
  ._addProject__container__projectDetails__body__box__left {
    width: 100%;
  }
}

._addProject__container__projectDetails__body__box__left__input {
  width: 100%;
  margin-bottom: 2rem;
}

._addProject__container__projectDetails__body__box__left__input:nth-last-child(1) label {
  display: flex;
  flex-direction: column;
}

._addProject__container__projectDetails__body__box__left__input:nth-last-child(1) label span {
  margin-top: 0.3rem;
  width: 35px;
}

._addProject__container__projectDetails__body__box__left__input__sliderFooter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

._addProject__container__projectDetails__body__box__left__input__sliderFooter__label {
  font-size: 0.7rem;
  color: gray;
  display: flex;
  flex-direction: row;
  align-items: center;
}

._addProject__container__projectDetails__body__box__left__input__sliderFooter__label__input {
  display: flex;
  align-items: center;
}

._addProject__container__projectDetails__body__box__left__input__sliderFooter__label__input input {
  outline: none;
  border: 1px solid lightgray;
  background-color: white;
  padding: 3px 5px;
  border-radius: 5px;
  width: 23px;
  margin: 0 0.2rem;
}

._addProject__container__projectDetails__body__box__left__input__sliderFooter__label__input span {
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 .2rem;
}

._addProject__container__projectDetails__body__box__left__input__inputText {
  display: flex;
  flex-direction: column;
}

._addProject__container__projectDetails__body__box__left__input__inputText label {
  font-size: 0.7rem;
  color: gray;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

._addProject__container__projectDetails__body__box__left__input__inputText input {
  border-radius: 3px;
  border: 1px solid lightgray;
  outline: #3380FF !important;
  padding: 0.65rem 0.5rem;
}

._addProject__container__projectDetails__body__box__left__input__inputText input::placeholder {
  font-size: 12px;
}

._addProject__container__projectDetails__body__box__right {
  display: flex;
  flex-direction: column;
  width: 48%;
}

@media (max-width: 768px) {
  ._addProject__container__projectDetails__body__box__right {
    width: 100%;
  }
}

._addProject__container__projectDetails__body__box__right__input {
  margin-bottom: 2rem;
  width: 100%;
}

._addProject__container__projectDetails__body__box__right__input__textarea {
  display: flex;
  flex-direction: column;
}

._addProject__container__projectDetails__body__box__right__input__textarea label {
  font-size: 0.7rem;
  color: gray;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

._addProject__container__projectDetails__body__box__right__input__textarea textarea {
  border-radius: 3px;
  border: 1px solid lightgray;
  outline: #3380FF !important;
  padding: 0.5rem;
}

._addProject__container__projectDetails__body__box__right__input__textarea textarea::placeholder {
  font-size: 12px;
}

._addProject__container__projectDetails__body__box__skillBox {
  width: 100%;
}

._addProject__container__projectDetails__body__box__skillBox__textFields {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  ._addProject__container__projectDetails__body__box__skillBox__textFields {
    display: flex;
    flex-direction: column;
  }
}

._addProject__container__projectDetails__body__box__skillBox__textFields__left {
  width: 48%;
}

@media (max-width: 768px) {
  ._addProject__container__projectDetails__body__box__skillBox__textFields__left {
    width: 100%;
  }
}

._addProject__container__projectDetails__body__box__skillBox__textFields__left__input {
  width: 100%;
  margin-bottom: 1rem;
}

._addProject__container__projectDetails__body__box__skillBox__textFields__right {
  width: 48%;
}

@media (max-width: 768px) {
  ._addProject__container__projectDetails__body__box__skillBox__textFields__right {
    width: 100%;
  }
}

._addProject__container__projectDetails__body__box__skillBox__textFields__right__input {
  width: 100%;
  margin-bottom: 1rem;
}

._addProject__container__projectDetails__body__box__skillBox__slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

._addProject__container__projectDetails__body__box__skillBox__slider__label {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: .5rem;
}

._addProject__container__projectDetails__body__box__skillBox__slider__label label {
  font-size: .7rem;
  color: gray;
  font-weight: 800;
}

._addProject__container__projectDetails__body__box__skillBox__slider__input {
  width: 50%;
}

@media (max-width: 768px) {
  ._addProject__container__projectDetails__body__box__skillBox__slider__input {
    width: 100%;
  }
}

._addProject__container__projectDetails__body__box__skillBox__slider__input:nth-last-child(1) label {
  display: flex;
  flex-direction: column;
}

._addProject__container__projectDetails__body__box__skillBox__slider__input:nth-last-child(1) label span {
  margin-top: 0.3rem;
  width: 35px;
}

._addProject__container__projectDetails__body__box__skillBox__slider__input__sliderFooter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

._addProject__container__projectDetails__body__box__skillBox__slider__input__sliderFooter__label {
  font-size: 0.7rem;
  color: gray;
  display: flex;
  flex-direction: row;
  align-items: center;
}

._addProject__container__projectDetails__body__box__skillBox__slider__input__sliderFooter__label__input {
  display: flex;
  align-items: center;
}

._addProject__container__projectDetails__body__box__skillBox__slider__input__sliderFooter__label__input input {
  outline: none;
  border: 1px solid lightgray;
  background-color: white;
  padding: 3px 5px;
  border-radius: 5px;
  width: 23px;
  margin: 0 0.2rem;
}

._addProject__container__projectDetails__body__box__skillBox__slider__input__sliderFooter__label__input span {
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 .2rem;
}

._addProject__container__offerButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

._addProject__container__offerButton Button {
  text-transform: capitalize;
  background-color: #3380FF !important;
}

@media (max-width: 768px) {
  ._addProject__container__offerButton Button {
    width: 100% !important;
    margin: 0 1rem !important;
  }
}

._applyFilters {
  transition: padding .5s, padding-left .5s, padding-right .5s;
  padding: 1rem 2rem 2rem 1rem;
  padding-left: 20%;
  padding-right: 20%;
}

@media (max-width: 1250px) {
  ._applyFilters {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (max-width: 1000px) {
  ._applyFilters {
    padding-left: unset;
    padding-right: unset;
    padding: 1rem;
  }
}

._applyFilters__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._applyFilters__header__buttons {
  display: flex;
  align-items: center;
}

._applyFilters__header__buttons__cancel {
  border-color: #3380FF !important;
  text-transform: capitalize !important;
  margin-right: 1rem !important;
}

._applyFilters__header__buttons__apply {
  background-color: #3380FF !important;
}

._applyFilters__container {
  margin-top: 1rem;
}

._applyFilters__container__box {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  ._applyFilters__container__box {
    display: flex;
    flex-direction: column;
  }

  ._applyFilters__container__box__contentBox {
    width: 100%;
  }
}

._applyFilters__container__box__contentBox {
  border: 1px solid lightgray;
  border-radius: 0.7rem;
  margin-top: 1rem;
  padding: 0 1rem 1rem 1rem;
  width: 49%;
}


._applyFilters__container__box__contentBox__label {
  font-size: 0.7rem;
  font-weight: 600;
  color: gray;
  position: absolute;
  margin-top: -0.5rem;
  background-color: white;
}

._applyFilters__container__box__contentBox__locationLanguage {
  margin-top: 0.7rem;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

._applyFilters__container__box__contentBox__locationLanguage__textField {
  width: 49%;
  margin-right: 0.5rem;
}

._applyFilters__container__box__contentBox__locationLanguage__textField:nth-last-child(1) {
  margin-right: 0;
}

._applyFilters__container__box__contentBox__gratification {
  margin-top: 0.7rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

._applyFilters__container__box__contentBox__gratification__textField {
  width: 150px;
  max-width: 30%;
  margin-right: 0.5rem;
}

._applyFilters__container__box__contentBox__gratification__textField:nth-last-child(1) {
  margin-right: 0;
}

._applyFilters__container__box__contentBox__gratification__for {
  font-size: 0.7rem;
  font-weight: 600;
  color: gray;
  margin-inline-end: 0.5rem;
}

._applyFilters__container__box__contentBox__gratification p {
  color: gray;
  font-size: 0.7rem;
  white-space: nowrap;
}

@media (max-width: 600px) {
  ._applyFilters__container__box__contentBox__gratification p {
    white-space: unset;
  }
}

._applyFilters__container__box__contentBox__projectType {
  margin-top: 1rem;
}

._applyFilters__container__box__contentBox__projectType__slider {
  margin-top: -0.5rem;
}

._applyFilters__container__box__contentBox__projectType__slider__sliderFooter {
  display: flex;
  justify-content: space-between;
  margin-top: -0.8rem;
}

._applyFilters__container__box__contentBox__projectType__slider__sliderFooter p {
  color: gray;
  font-size: 0.7rem;
}

._applyFilters__container__box__contentBox__projectType__slider__sliderFooter p input {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 3px;
  margin: 0 0.3rem;
  width: 23px;
  outline: none;
}

._applyFilters__container__box__contentBox__projectType__slider__sliderFooter p span {
  border: 1px solid lightgray;
  margin: 0 .2rem;
  padding: 0rem .2rem;
  border-radius: 5px;
}

._applyFilters__container__box__contentBox__workingType {
  margin-top: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

._applyFilters__container__box__contentBox__workingType__textField {
  width: 6rem;
  display: flex;
  align-items: center;
}

._applyFilters__container__box__contentBox__workingType__textField input {
  width: 1rem;
  height: 1rem;
}

._applyFilters__container__box__contentBox__workingType__textField span {
  font-weight: 600;
  font-size: 0.7rem;
  color: gray;
  margin-left: 0.5rem;
}

._addedSkillerSidebar__button {
  text-transform: capitalize !important;
  background-color: #3380FF !important;
}

._clicked-button {
  background-color: white !important;
  color: #3380FF !important;
}

._cSelect__label {
  white-space: nowrap;
}

._home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  padding-top: 7rem;
}

._home__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  width: 80%;
}

@media (max-width: 1200px) {
  ._home__container {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  ._home__container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  ._home__container {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
}

._home__container__left {
  padding-right: 2rem;
}

@media (max-width: 768px) {
  ._home__container__left {
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;
  }
}

._home__container__left p {
  font-weight: 600;
}

._home__container__left h3 {
  margin-top: .2rem;
  font-size: 2rem;
  margin-bottom: .5rem;
}

._home__container__left h3 b {
  font-weight: 800;
}

._home__container__left span {
  font-size: .8rem;
}

._home__container__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  ._home__container__right {
    width: 100%;
  }
}

._home__container__right__card {
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
  background-color: white;
  box-shadow: 0 0.5rem 1.5rem #d3d3d3;
  margin: .5rem 0;
  width: 350px;
  text-decoration: unset;
  border: 2px solid transparent;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}

@media (max-width: 768px) {
  ._home__container__right__card {
    width: 100%;
  }
}

._home__container__right__card__iconBox {
  margin-right: 1rem;
  margin-left: 1rem;
}

._home__container__right__card__iconBox__icon {
  color: lightgray;
}

._home__container__right__card__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

._home__container__right__card__details__text {
  display: flex;
  flex-direction: column;
  padding: .3rem 0;
  color: black;
}

._home__container__right__card__details__text p {
  font-weight: 600;
}

._home__container__right__card__details__text span {
  font-size: .8rem;
  margin-top: .3rem;
}

._home__container__right__card__details__forwardIcon1 {
  background-color: #FFF1DB;
}

._home__container__right__card__details__forwardIcon {
  width: 45px;
  height: 85px;
  border-radius: 0 .8rem .8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

._home__container__right__card__details__forwardIcon__icon1 {
  color: #FFB849;
  background-color: #FFF1DB;
}

._home__container__right__card__details__forwardIcon__icon {
  font-size: 1rem !important;
  color: white;
}

._home__container__right__card1 {
  border: 2px solid #F9D08D;
}

._home__container__right__card1__iconBox__icon1 {
  color: #FFB849;
}

._home__container__right__card1__details__forwardIcon1 {
  background-color: #FFF1DB;
}

._home__container__right__card1__details__forwardIcon1__icon1 {
  color: #FFB849;
  background-color: #FFF1DB;
}

._home__container__right__card2 {
  border: 2px solid #3380FF;
}

._home__container__right__card2__iconBox__icon2 {
  color: #3380FF;
}

._home__container__right__card2__details__forwardIcon2 {
  background-color: #D6E6FF;
}

._home__container__right__card2__details__forwardIcon2__icon2 {
  color: #3380FF;
  background-color: #D6E6FF;
}

._home__container__right__card3 {
  border: 2px solid #9181DB;
}

._home__container__right__card3__iconBox__icon3 {
  color: #9181DB;
}

._home__container__right__card3__details__forwardIcon3 {
  background-color: #E9E6F8;
}

._home__container__right__card3__details__forwardIcon3__icon3 {
  color: #9181DB;
  background-color: #E9E6F8;
}

._home__container__right__card4 {
  border: 2px solid #D06D6D;
}

._home__container__right__card4__iconBox__icon4 {
  color: #D06D6D;
}

._home__container__right__card4__details__forwardIcon4 {
  background-color: #FBE7E7;
}

._home__container__right__card4__details__forwardIcon4__icon4 {
  color: #D06D6D;
  background-color: #FBE7E7;
}

._homeCardsView {
  padding: 7rem 2rem 2rem 2rem;
}

@media (max-width: 768px) {
  ._homeCardsView {
    padding: 7rem 1rem 1rem 1rem;
  }
}

._homeCardsView__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

._homeCardsView__header__headingAccount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
}

._homeCardsView__header__headingAccount span {
  font-size: .8rem;
  margin-top: .5rem;
}

._homeCardsView__header__backIcon__icon {
  color: black !important;
}

._homeCardsView__header p {
  font-weight: 800;
  margin-left: .5rem;
}

._homeCardsView__container {
  display: flex;
  flex-direction: column;
}

._homeCardsView__container__projectCards {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 5rem 0;
}

@media (max-width: 768px) {
  ._homeCardsView__container__projectCards {
    margin: 1rem;
  }
}

._homeCardsView__container__projectCards__card {
  margin: 1rem;
  background-color: white;
  border-radius: .8rem;
  padding: 6rem 1rem 3rem 1rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  border:2px solid transparent;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}

@media (max-width: 768px) {
  ._homeCardsView__container__projectCards__card {
    margin: 1rem 0.5rem;
  }
}

@media (max-width: 500px) {
  ._homeCardsView__container__projectCards__card {
    width: 100%;
    margin: 1rem 0;
  }
}

._homeCardsView__container__projectCards__card__iconBox__icon {
  font-size: 3rem !important;
  color: gray;
}

._homeCardsView__container__projectCards__card p {
  font-weight: 500;
  margin-top: 2rem;
  color: black;
  font-size: .9rem;
}

._homeCardsView__container__projectCards__card span {
  font-size: .8rem;
  margin-top: .3rem;
}

._homeCardsView__container__projectCards__cardProject {
  border: 2px solid #FFB849;
}

._homeCardsView__container__projectCards__cardProject__iconBox__iconProject {
  font-size: 3rem !important;
  color: #FFB849 !important;
}

._homeCardsView__container__projectCards__cardSkill {
  border: 2px solid #9181DB;
}

._homeCardsView__container__projectCards__cardSkill__iconBox__iconSkill {
  font-size: 3rem !important;
  color: #9181DB !important;
}

._homeCardsView__container__projectCards__cardAccount {
  border: 2px solid #3380FF;
}

._homeCardsView__container__projectCards__cardAccount__iconBox__iconAccount {
  color: #3380FF !important;
  font-size: 3rem !important;
}

._homeCardsView__container__continueButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media (max-width: 768px) {
  ._homeCardsView__container__continueButton {
    display: flex;
    justify-content: center;
  }
}

._homeCardsView__container__continueButton Button {
  text-transform: capitalize;
  background-color: #3380FF !important;
}

@media (max-width: 500px) {
  ._homeCardsView__container__continueButton Button {
    width: 100%;
    margin: 0 1rem;
  }
}

._conversations {
  padding: 5rem 2rem 0 2rem;
  margin-bottom: 1rem;
}

@media (max-width: 1000px) {
  ._conversations {
    padding: 7rem 2rem 0 2rem;
  }
}

@media (max-width: 768px) {
  ._conversations {
    padding: 7rem 1rem 0 1rem;
  }
}

._conversations__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1000px) {
  ._conversations__header {
    margin-bottom: .5rem;
  }
}

._conversations__header__sidebar {
  visibility: hidden;
}

@media (max-width: 768px) {
  ._conversations__header__sidebar {
    visibility: visible;
  }
}

._conversations__header__people {
  visibility: hidden;
}

@media (max-width: 1000px) {
  ._conversations__header__people {
    visibility: visible;
  }
}

._conversations__container {
  display: flex;
  justify-content: space-between;
}

._conversations__container__sidebar {
  border-radius: .8rem;
}

@media (max-width: 768px) {
  ._conversations__container__sidebar {
    display: none;
  }
}

._peopleBox {
  min-height: 76vh;
  border-radius: .8rem;
  overflow-y: auto;
}

@media (max-width: 1000px) {
  ._peopleBox {
    display: none;
  }
}

._foundSkillers {
  padding: 5rem 2rem 0 2rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  ._foundSkillers {
    padding: 7rem 1rem 0 1rem;
  }
}

._foundSkillers__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1000px) {
  ._foundSkillers__header {
    margin-bottom: .5rem;
  }
}

._foundSkillers__header__sidebar {
  visibility: hidden;
}

@media (max-width: 768px) {
  ._foundSkillers__header__sidebar {
    visibility: visible;
  }
}

._foundSkillers__container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  ._foundSkillers__container__sidebar {
    display: none;
  }
}

._foundSkillers__container__skills {
  height: 76vh;
  overflow-y: auto;
  border-radius: .8rem;
  flex: 1;
  margin-left: 196px;
}

@media (max-width: 768px) {
  ._foundSkillers__container__skills {
    margin-left: 0;
  }
}

._settings {
  padding: 5rem 2rem 0 2rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  ._settings {
    padding: 7rem 1rem 0 1rem;
  }
}

._settings__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1000px) {
  ._settings__header {
    margin-bottom: .5rem;
  }
}

._settings__header__sidebar {
  visibility: hidden;
}

@media (max-width: 768px) {
  ._settings__header__sidebar {
    visibility: visible;
  }
}

._settings__container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  ._settings__container__sidebar {
    display: none;
  }
}

._settings__container__description {
  display: flex;
  justify-content: center;
  margin-left: 196px;
  width: 100%;
}

@media (max-width: 768px) {
  ._settings__container__description {
    margin-left: 0;
  }
}

._settings__container__description__box {
  width: 80%;
}

@media (max-width: 1000px) {
  ._settings__container__description__box {
    width: 100%;
  }
}

._settings__container__description__box__settingTypes {
  display: flex;
  flex-wrap: wrap;
}

._settings__container__description__box__settingTypes Button {
  margin-right: .5rem;
  border-radius: 1rem !important;
  text-transform: capitalize;
  margin-bottom: .5rem !important;
  color: #3380FF !important;
  border-color: #3380FF !important;
  font-weight: 500 !important;
}

._settings__container__description__box__details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  ._settings__container__description__box__details {
    display: flex;
    flex-direction: column;
  }
}

._settings__container__description__box__details__input {
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-bottom: 1rem;
  margin-right: .5rem;
}

@media (max-width: 768px) {
  ._settings__container__description__box__details__input {
    width: 100%;
    margin-right: 0;
  }
}

._settings__container__description__box__details__input label {
  font-size: .8rem;
  font-weight: 600;
  margin-bottom: .3rem;
}

._settings__container__description__box__details__input input {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: .7rem .5rem;
}

._settings__container__description__box__saveButton {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

._settings__container__description__box__saveButton Button {
  background-color: #3380FF !important;
}

@media (max-width: 768px) {
  ._settings__container__description__box__saveButton Button {
    width: 100% !important;
  }
}

._settingsMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
}

._settingsMessage__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

._settingsMessage__container__topIcon {
  margin-top: 3rem;
}

._settingsMessage__container__topIcon__icon {
  font-size: 5rem !important;
}

._settingsMessage__container h4 {
  margin-top: 2rem;
}

._settingsMessage__container__message {
  text-align: center;
  margin-top: .5rem;
  font-size: .9rem;
}

._settingsMessage__container__actions {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

._settingsMessage__container__actions__moreOffer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 1.5rem;
  border-radius: .5rem;
  margin: 0 1rem;
  width: 150px;
  height: 130px;
  margin-bottom: 1rem;
}

._settingsMessage__container__actions__moreOffer__icon {
  font-size: 3rem !important;
  color: lightgray;
}

._settingsMessage__container__actions__moreOffer p {
  text-align: center;
  margin-top: 1rem;
  font-weight: 600;
  font-size: .7rem;
  white-space: nowrap;
}

._myProjects {
  padding: 5rem 2rem 0 2rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  ._myProjects {
    padding: 7rem 1rem 0 1rem;
  }
}

._myProjects__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1000px) {
  ._myProjects__header {
    margin-bottom: .5rem;
  }
}

._myProjects__header__sidebar {
  visibility: hidden;
}

@media (max-width: 768px) {
  ._myProjects__header__sidebar {
    visibility: visible;
  }
}

._myProjects__container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  ._myProjects__container__sidebar {
    display: none;
  }
}

._myProjects__container__savedProjects {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 196px;
}

@media (max-width: 768px) {
  ._myProjects__container__savedProjects {
    margin-left: 0;
  }
}

._myProjects__container__savedProjects__addProject {
  display: flex;
  justify-content: flex-end;
}

._myProjects__container__savedProjects__addProject Button {
  text-transform: capitalize;
  background-color: #3380FF;
}

._myProjects__container__savedProjects__message {
  height: 76vh;
  overflow-y: auto;
  border-radius: .8rem;
}

._mySkillers {
  padding: 5rem 2rem 0 2rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  ._mySkillers {
    padding: 7rem 1rem 0 1rem;
  }
}

._mySkillers__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1000px) {
  ._mySkillers__header {
    margin-bottom: .5rem;
  }
}

._mySkillers__header__sidebar {
  visibility: hidden;
}

@media (max-width: 768px) {
  ._mySkillers__header__sidebar {
    visibility: visible;
  }
}

._mySkillers__container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  ._mySkillers__container__sidebar {
    display: none;
  }
}

._mySkillers__container__savedSkillers {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 196px;
}

@media (max-width: 768px) {
  ._mySkillers__container__savedSkillers {
    margin-left: 0;
  }
}

._mySkillers__container__savedSkillers__addSkiller {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._mySkillers__container__savedSkillers__addSkiller Button {
  text-transform: capitalize;
  background-color: #3380FF;
}

._mySkillers__container__savedSkillers__message {
  margin-top: -8rem;
  margin-left: -2rem;
  margin-right: -2rem;
}

@media (max-width: 1300px) {
  ._mySkillers__container__savedSkillers__message {
    margin-left: -4rem;
    margin-right: -4rem;
  }
}

@media (max-width: 1000px) {
  ._mySkillers__container__savedSkillers__message {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

._sendMoreOffers {
  padding: 7rem 2rem 2rem 2rem;
}

@media (max-width: 768px) {
  ._sendMoreOffers {
    padding: 7rem 1rem 1rem 1rem;
  }
}

._hover-container {
  position: relative;
  display: inline-block;
}

._label {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 5px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

._hover-container:hover ._label {
  display: block;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}



._right_arrow_div {
  max-width: 65px;
  height: 133px;
  display: flex !important;
  justify-content: end !important;
  align-items: center;
  position: absolute;
  right: 20%;
  top: 40%;
}

._right_arrow_icon {
  width: 65.5px;
  height: 66.5px;
  color: #ffb849;
  font-size: 70px !important;
}

._right_arrow_icon:hover {
  color: #f3b85a;

}

._right_arrow_div2 {
  max-width: 65px;
  height: 133px;
  display: flex !important;
  justify-content: end !important;
  align-items: center;
  position: absolute;
  right: 20%;
  top: 40%;
}


._left_arrow_div {
  max-width: 65px;
  height: 133px;
  display: flex !important;
  justify-content: start !important;
  align-items: center;
  position: absolute;
  left: 20%;
  top: 40%;
}

._left_arrow_div2 {
  max-width: 65px;
  height: 133px;
  display: flex !important;
  justify-content: start !important;
  align-items: center;
  position: absolute;
  left: 20%;
  top: 40%;
}


._arrow_btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

._arrow_btn img {
  width: 50%;
}


._circle_yellow_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 29px;
  margin-left: auto;
  margin-right: auto;
}

._active_yellow_circle {
  width: 20px;
  height: 20px;
  background-color: #FFB849;
  border-radius: 50%;
  cursor: pointer;
}

._inactive_yellow_circle {
  width: 20px;
  height: 20px;
  border: 3px solid #FFB849;
  border-radius: 50%;
}

._buttons_container {
  position: relative;
  /* bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

._submit_btn {
  // margin: 0 auto !important;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: #FFB849;
  min-width: 200px;
  height: 51px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer !important;
  border: none !important;
}

._submit_btn1 {
  margin: 0 auto !important;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: #FFB849;
  min-width: 200px;
  height: 51px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer !important;
  border: none !important;

}

._submit_btn1:focus {
  background-color: #f3b85a;
}

._submit_btn:focus {
  background-color: #f3b85a;
}

._submit_btn1:hover {
  background-color: #f3b85a;
}

._submit_btn:hover {
  background-color: #f3b85a;
}

._page1_component {
  margin-top: 210px !important;
  gap: 25px 0px !important;
  max-width: 400px;
  margin: 0 20px;
}

._page2_component {
  margin-top: 235px !important;
  gap: 25px 0px !important;
  max-width: 400px;
  margin: 0 20px;

}

._page3_component {
  margin-top: 235px !important;
  gap: 25px 0px !important;
  max-width: 400px;
  margin: 0 20px;
}

._component5_select {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: #FFF;
  width: 300px;
  height: 50px !important;
}



._set_border {
  border: 2px solid #F00;
}

._set_border_2_grey {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: #FFF;
}

._component {
  width: 100%;
  background: #FFF;
  padding: 13px;
  border-radius: 10px;
  background: #FFF;
  position: relative;
}

._show_component1 {
  position: absolute;
  z-index: 99999999999999;
  background: #ffffff;
  width: 100.6%;
  padding: 30px !important;
  margin: 0 !important;
  border-left: 1px solid rgba(0, 0, 0, 0.20);
  border-right: 1px solid rgba(0, 0, 0, 0.20);
  border-bottom: 1px solid rgba(0, 0, 0, 0.20);
  left: -1px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

._show_component {
  position: absolute;
  z-index: 99999999999999;
  background: #ffffff;
  width: 100.6%;
  padding: 30px !important;
  margin: 0 !important;
  left: -1px;
  border-left: 1px solid rgba(0, 0, 0, 0.20);
  border-right: 1px solid rgba(0, 0, 0, 0.20);
  border-bottom: 1px solid rgba(0, 0, 0, 0.20);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

._search_field {
  border: 1px solid grey;
}

._search_input {
  border: 1px solid rgba(0, 0, 0, 0.20);

  border-radius: 8px;
  margin-top: 15px;
  // width: 253px;
  height: 33px;
}

._search_input1 {
  border: 1px solid rgba(0, 0, 0, 0.20);
  width: 100%;
  height: 55px !important;
  background: #FFF;
  padding: 13px;
  // margin: 25px auto !important;
  display: flex;
  border-radius: 10px;
  
  background: #FFF;
}

._search_input1::placeholder {
  color: #000;
  font-size: 14px;
}

._search_input:focus {
  border: 1px solid rgba(0, 0, 0, 0.20);
}

._search_input1:focus {
  border: 2px solid #F00 !important;

}

._search_container {
  text-align: left;
}

._picked {
  text-align: left;
}

._heading {
  max-height: 50px;
}

._component_four {
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: #FFF;
  padding: 13px;
  // max-height: 50px; 
  position: relative;
}

._component_full_five {
  height: auto;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.20);
  background: #FFF;
  padding: 13px;
  margin: 50px auto;
  max-width: 300px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.073px;
  padding-left: 0px !important;
}

._component_full_five ._heading {
  padding-left: 16px;
  padding-top: 10px;
}

._picked_heading {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.073px;
}

._option_text_design {
  padding: 5px 8px;
  flex-shrink: 0;
  border-radius: 19px;
  background: rgba(94, 110, 120, 0.10);
  color: #5E6E78;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  line-height: 17px;
}

._picked ._option_text_design {
  background: rgba(94, 110, 120, 0.20);
}

._all_checkbox {
  margin-bottom: 7px;
  display: flex;
  justify-content: start;
  align-items: center !important;
  gap: 5px;
}

._all_options_hover:hover ._inner_box {
  border-radius: 3px;
  background: rgba(94, 110, 120, 0.40);
  width: 100%;
  height: 100%;
}

._all_options_hover:hover ._option_text_design {
  background: rgba(94, 110, 120, 0.20);
  color: #5E6E78;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  border-radius: 5px;
  transition: 0.3s ease-in;
}

._all_checkbox input {
  color: grey;
}

._all_heading {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.073px;
}

._option_design {
  padding-left: 16px;

}

._title {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.073px;
}

._react_arrow {
  font-size: 20px !important;
}

._checkbox {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 2px solid rgba(94, 110, 120, 0.40);
  background: rgba(217, 217, 217, 0.00);
  padding: 1px;
  flex-shrink: 0;
}

._checkbox:hover {
  background: #5E6E7866;
  cursor: pointer;
  border-radius: 3px;
}

._picked ._inner_box {
  border-radius: 3px;
  background: #FFB849;
  width: 100%;
  transition: 0.3s ease-in;
  height: 100%;
}

._all_checkbox ._inner_box:hover {
  background: #5E6E7866;
  cursor: pointer;
}

._option {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  /* margin-top: 5px; */
  font-size: 14px;
  // padding: 10px;
}

._option p {
  padding: 7px;
  margin-right: 10px;
}

._title p {
  cursor: pointer;
}

._component {
  cursor: pointer;
}


._project_name {
  width: 100% !important;
  height: 100% !important;
  border: none;
}

._title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._title p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 27.073px;
}

._rotate {
  transform: rotate(180deg);
}

._option {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-bottom: 5px;

}

._all_options_scroll {
  /* overflow-y: scroll; */
  max-height: 548.502px;

}

._picked {
  margin-bottom: 20px;
}

._picked h6,
._all h6 {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 27.073px;
}

._checkboxn {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 2px solid rgba(94, 110, 120, 0.40);
  background: rgba(217, 217, 217, 0.00);
  padding: 1px;
}

._picked ._inner_box {
  border-radius: 3px;
  background: #FFB849;
  width: 100%;
  height: 100%;
}

._picked p {
  border-radius: 19px;
  background: rgba(94, 110, 120, 0.20);
  padding: 5px 8px;
  color: #5E6E78;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
}




._all ._inner_box {
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  transition: 0.3s ease-in;
}

._all ._option:hover ._op {
  background: rgba(94, 110, 120, 0.20);
  color: #5E6E78;


}

._all ._option:hover ._p_option {
  background: rgba(94, 110, 120, 0.20);
  color: #5E6E78;
}

._all ._option:hover p {
  background: rgba(94, 110, 120, 0.20);
  color: #5E6E78;
  border-radius: 40px;
  /* padding: 3px; */
}

._option:hover ._input_option_designp {
  display: block;

}

._all ._option:hover ._inner_box {
  border-radius: 3px;
  background: rgba(94, 110, 120, 0.40);
  width: 100%;
  height: 100%;
}

._p_option:hover {
  background: rgba(94, 110, 120, 0.20) !important;
  color: #5E6E78 !important;
}

._input_option_design {
  width: 50px;
  border: none;
  /* display: none; */
  text-align: center;
}

._hover_items {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

._click_box {

  border-radius: 19px;
  background: rgba(94, 110, 120, 0.20);
  padding: 5px 8px;
  color: #5E6E78;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
}

._center_section_text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

}

._ppppp {
  display: none;
}


._i_d_btn {

  display: none;
  padding: 0;

}

._i_d_btn button {
  border: none;
  background-color: transparent;
  padding: 0 !important;
  margin: 0 !important;
  height: 7px;

}

._all ._option:hover ._ppppp {
  display: block;
}

._all ._option:hover ._i_d_btn {
  display: flex;
  gap: 2px;
  flex-direction: column;
  align-items: center !important;
  padding: 0;
}

._mobile_device {
  display: none;
}

._page2_icons_mobile {
  display: flex;
  justify-content: end;
  display: none;
  opacity: 0;
}

._page3_left_icon {
  text-align: end;
  display: none;
  opacity: 0;
}

._projects__body__container__left__card__top ._user_icon {
  width: 20px !important;
  height: 20px !important; 
}

/* dh classes */
._page1_component {
  gap: 25px 0px !important;
  max-width: 400px;
  margin: auto;
  // height: 520px !important;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 5%;
}

._page2_component {
  gap: 25px 0px !important;
  max-width: 400px;
  margin: auto;
  // height: 520px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

._page3_component {
  gap: 25px 0px !important;
  max-width: 400px;
  margin: auto;
  // height: 520px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media(max-width:860px) {
//   ._projects__body__container__left__card__body {
//     margin-top: 10px !important;
//   }

  ._buttons_container {
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ._page1_component {
    margin-top: 20% !important;

  }

  ._page2_component {
    margin-top: 20% !important;

  }

  ._page3_component {
    margin-top: 20% !important;

  }

  ._mobile_device {
    display: flex !important;
    justify-content: space-between;
  }

  ._desktop_right_arrow {
    display: none !important;
  }

  ._page2_icons_mobile {
    display: flex;
    justify-content: space-between;
    opacity: 1;
  }

  ._projects__body__container__left__card2_icons_desktop {
    display: none;
    opacity: 0;
  }

  ._page3_left_icon {
    display: block;
    opacity: 1;
    text-align: left;
  }

  ._desktop_icons {
    display: none;
    opacity: 0;
  }

  ._arrow_btn img {
    width: 40%;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Staatliches&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._home_margin {
  margin: 100px !important;
}

._container {
  max-width: 1577px !important;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

._flag_design {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  gap: 2px;
  padding: 5px 8px;
  border-radius: 5px !important;
  color: #5E6E78;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  /* 130.769% */
  height: 24px;
  position: relative;
  cursor: pointer;
  // white-space: nowrap; /* Prevents text wrapping */
  // overflow: hidden; /* Optional: hides overflowing content */
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowing content */
  text-overflow: ellipsis; /* Adds "..." to overflowing text */
  width: 120px; /* Set the width of the container */
}

// /* Hover state to show full text */
// ._flag_design:hover {
//   white-space: normal; /* Allow text to wrap */
//   overflow: visible; /* Show the full content */
//   text-overflow: unset; /* Remove the ellipsis */
// }

// /* Optional: Style for full text display */
// ._flag_design:hover::after {
//   content: attr(data-text); /* Use data attribute for the full text */
//   position: absolute; /* Position the full text */
//   left: 0; /* Align with the container */
//   top: 100%; /* Position it below the container */
//   background: white; /* Background color for better readability */
//   border: 1px solid #ddd; /* Border for the tooltip */
//   padding: 5px; /* Padding for the tooltip */
//   white-space: normal; /* Allow text to wrap in the tooltip */
//   width: max-content; /* Adjust width to fit the text */
//   z-index: 10; /* Ensure the tooltip is above other content */
// }

._flag_design_small {
  // width: 120px;
  cursor: pointer;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowing content */
  text-overflow: ellipsis; /* Adds "..." to overflowing text */
  width: 140px; /* Set the width of the container */
  // background-color: ;
}

/* Hover state to show full text */
._flag_design_small:hover {
  white-space: normal; /* Allow text to wrap */
  overflow: visible; /* Show the full content */
  text-overflow: unset; /* Remove the ellipsis */
}

/* Hover state container for full text */
._flag_design_small:hover::after {
  content: attr(data-text); /* Display full text from data attribute */
  position: absolute; /* Position relative to the parent */
  left: 0; /* Align with the left edge of the parent */
  top: 100%; /* Position below the parent element */
  padding: 5px; /* Padding for spacing */
  width: max-content; /* Adjust width to fit the content */
  z-index: 10; /* Ensure it's above other content */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  gap: 3px; /* Gap between lines of text */
  transition: 0.3s ease-in; /* Smooth transition */
  white-space: normal; /* Allow text to wrap in the hover container */
}

._flag_design_hours_weekly {
  width: 40px;
  cursor: pointer;
}

// @media (max-width:375px) {
//   ._flag_design {
//     width: max-content;
//     cursor: pointer;
//   }

// }


._flag_design img {
  height: 10px;
  width: 10px;
}

._hover_red_text {
  visibility: hidden;
  transition: 0.3s ease-in;
  opacity: 0;
  position: absolute;
  top: -85%;
  left: 84%;
  white-space: nowrap;
  color: #FFF !important;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  border-radius: 19px;
  background: rgba(246, 52, 52, 0.80) !important;

  line-height: 17px;
  padding: 2px 8px;
}

._yellow_parent {
  position: relative;
  cursor: pointer;
  // width: 100px;
}

._yellow_text {
  position: absolute;
  top: -211%;
  left: 90%;
  padding: 5px 8px;
  border-radius: 19px;
  background: #FFB849;
  color: #FFF;
  font-family: Montserrat;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  white-space: nowrap;
  visibility: hidden;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.3s ease-in;
}


._yellow_text1 {
  position: absolute;
  top: -211%;
  left: 50%;
  padding: 5px 8px;
  border-radius: 19px;
  background: #FFB849;
  color: #FFF;
  font-family: Montserrat;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in;

}

._question_hover:hover ._yellow_text1 {
  visibility: visible !important;
  opacity: 1;
}

._question_hover:hover ._yellow_text {
  visibility: visible !important;
  opacity: 1;
}

// ._red_hover_tool:hover ._hover_red_text {
//   visibility: visible;
//   opacity: 1;
// }

._border_right {
  width: 1.1px !important;
  height: 18px !important;
  border-radius: 100px !important;
  background: rgba(94, 110, 120, 0.30);
}

._question_parent {
  width: fit-content;
  position: relative;
  margin: auto; // Center the entire element within its parent
  text-align: center; // Center the text within this element
  padding-bottom: 0.5em;
}

._question_parent1 {
  position: relative;
  width: fit-content;
}

._question_image {
  position: absolute;
  right: -4%;
  // right: -0%;
  top: -67%;
  width: 12px !important;
  height: 12px !important;
}

._question_image1 {
  position: absolute;
  right: -8%;
  top: -67%;
  width: 12px !important;
  height: 12px !important;
}

._red_text_parent small {
  position: absolute;
  left: 8%;
  z-index: 999999;
}

._dot_container {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 7px;
  margin-top: 6px;
  position: relative !important;
}

._dot_container small {
  border-radius: 20px;
  background: rgba(94, 110, 120, 0.80);
  color: rgba(255, 255, 255, 0.80);
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  // line-height: 17px;
  /* 130.769% */
  padding: 5px 5px;
  height: 24px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

._feature_box_tools {
  width: 140px;
  white-space: nowrap;
}

._feature_box_project_type {
  width: 140px;
  white-space: nowrap;
}

._feature_box_paid_with {
  width: 140px;
  white-space: nowrap;
}

._feature_box_paid_for {
  width: 140px;
  white-space: nowrap;
}

._feature_box_working_type {
  width: 140px;
  white-space: nowrap;
}

._feature_box_industry {
  width: 140px;
  white-space: nowrap;
}

._feature_box_languages {
  width: 140px;
  white-space: nowrap;
}

._feature_box_soft_skills {
  width: 140px;
  white-space: nowrap;
}

._feature_box_work_duration {
  width: 140px;
  white-space: nowrap;
}

._feature_box_biz_or_tech {
  width: 140px;
  white-space: nowrap;
}

._feature_box_weekly_availability {
  width: 140px;
  white-space: nowrap;
}

._feature_box_location {
  width: 140px;
  white-space: nowrap;
}

._header_p {
  color: rgba(0, 0, 0, 0.75);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  /* 121.429% */
  text-transform: uppercase;
}

._dot_hover {
  display: flex;
  justify-content: center;
  gap: 6.5px;
  flex-direction: column;
  
}


._dot_hover_element {
  display: flex;
  flex-direction: column;
  gap: 3px;
  transition: 0.1s ease-in;
  position: absolute;
  left: 20px;
  top: 0px;
  white-space: nowrap;
}

@media (max-width:768px) {

  ._dot_hover_element1 {
    // left: 20px;
    top: 0px !important;
  }

  ._dot_hover_element {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;

  }

}

._dot_hover ._dot_hover_element {
  visibility: hidden;
  opacity: 0;
}

._dot_hover ._dot_image:hover+._dot_hover_element {
  visibility: visible;
  opacity: 1;

}

._dot_icon {
  fill: red; 
  width: '25px';
  height: '25px';
}

._small_visible {
  display: none;
}

._all h6 {
  margin-bottom: 10px;
  font-size: 16px !important;
  font-weight: bold !important;
}

._picked h6 {
  margin: 10px 0;
  font-size: 16px !important;
  font-weight: bold !important;
}

@media (max-width:768px) {
  // ._small_none {
  //   display: none;
  // }

  // ._small_visible {
  //   display: block;
  // }

  // ._projects__body__container__left__card__body2__specs__desc {
  //   margin-top: 4px !important;
  // }

  ._home_margin {
    margin: 0px !important;
  }

}

// @media(min-width:867px) and (max-width:984px) {
//   ._projects__body__container__left__card__body {
//     margin-top: 10px !important;
//   }
// }

// @media(min-width:1360px) and (max-width:1503px) {
//   ._projects__body__container__left__card__body__specs {
//     margin-right: 5px !important;
//   }

//   ._dot_container {
//     margin-right: -36px;
//   }
// }

._team_mate_boxes {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
}

._box_title_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

._save_button {
  background-color: #327FFF;
  padding: 7px 0;
  border-radius: 20px;
  cursor: pointer;
  width: 100px;
  color: white;
  border: none;
}

._forgetButton {
  background-color: #ff0059;
  padding: 7px 0;
  border-radius: 20px;
  cursor: pointer;
  width: 100px;
  color: white;
  border: none;
}

._css-i4bv87-MuiSvgIcon-root {
  transition: all 0.3s !important;
}
.navLinkBox {
  position: relative;
  margin: 0 0.4em;
}

.dropdown {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 2.5em;
  left: -1em;
  background-color: #fff;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  overflow: hidden;
  &__link {
    padding: 1em;
    width: 100%;
    transition: background-color 0.3s;
    &:hover {
      background-color: #eff0f1;
    }
  }
}

.homeBox {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  margin-top: 4em;
  padding: 2em 2em;
  width: 90%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.nameBox {
  margin-bottom: 2em;
  text-align: center;
  &__welcome {
    font-size: 1.6rem;
  }

  &__brandName {
    font-size: 3rem;
    font-weight: normal;
    span {
      font-weight: bold;
    }
  }
}

.logSigBox {
  &__error {
    font-size: 0.9rem;
    display: block;
    text-align: center;
    color: red;
  }

  &__btns {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  &__noAccountText {
    margin-top: 0.5em;
  }

  &__logInBtn {
    position: relative;
    margin: 0.4em auto;
    width: 80%;
    padding: 0.6em 1em;
    font-size: 1rem;
    border: 1px solid #fff;
    color: white;
    background-color: #3380ff;
    border-radius: 12px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform: scaleX(0);
      background-color: #2e79f1;
      transition: transform 0.3s;
      transform-origin: right;
      z-index: -1;
      content: "";
    }
    &:hover::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}

.underline {
  margin: 0.5em auto;
  width: 60%;
  height: 3px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
  .nameBox {
    margin-bottom: 3em;
  }
  .logSigBox {
    &__btns {
      margin-top: 3em;
    }
  }
  .box {
    width: 60%;
  }
  .logSigBox {
    &__logInBtn {
      font-size: 1.2rem;
    }
  }
}
@media (min-width: 992px) {
  .box {
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .box {
    width: 30%;
  }
}

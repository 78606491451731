@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

.component {
  // max-width: 300px;
  // border-radius: 10px;
  // border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  // padding: 13px;
  // margin: 50px auto;
  // margin-bottom: 25px;
}

.component.selected {
  /* max-width: 300px; */
  border-radius: 10px;
  border: 1px solid #337eff;
  background: #fff;
}


.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title p {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 27.073px;
}

.rotate {
  transform: rotate(180deg);
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.all_options_scroll {
    overflow-y: scroll;
    height: fit-content;
    max-height: 300px;
}

.picked {
  // margin-bottom: 20px;
 
 
  width: 100%;
}
.all {
  

  width: 100%;
}

.picked h6,
.all h6 {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 27.073px;
}

.checkboxn {
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 2px solid rgba(94, 110, 120, 0.4);
  background: rgba(217, 217, 217, 0);
  padding: 1px;
  flex-shrink: 0;
}

.picked .inner_box {
  border-radius: 3px;
  background: #ffb849;
  width: 100%;
  height: 100%;
}

.picked p {
  border-radius: 19px;
  background: rgba(94, 110, 120, 0.2);
  // padding: 5px 8px;
  color: #5e6e78;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
}

.all .op {
  color: #5e6e78;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  border-radius: 19px;
  background: rgba(94, 110, 120, 0.1);
  // padding: 8px;
  transition: 0.3s ease-in;
  display: flex;
  gap: 10px;
  align-items: center;
  height: auto;
  margin-right: 10px;
}

// .all .op > * {
//     flex-shrink: 0;
// }

/* .all .option {
    color: #5E6E78;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    border-radius: 19px;
    background: rgba(94, 110, 120, 0.10);
    padding: 5px 8px;
    transition: 0.3s ease-in;
    display: flex;
    gap: 10px;
    align-items: center;
} */

.all .inner_box {
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  transition: 0.3s ease-in;
}

.all .option:hover .op {
  background: rgba(94, 110, 120, 0.2);
  color: #5e6e78;
}

.all .option:hover .p_option {
  background: rgba(94, 110, 120, 0.2);
  color: #5e6e78;
}

.all .option:hover p {
  background: rgba(94, 110, 120, 0.2);
  color: #5e6e78;
}

.option:hover .input_option_designp {
  display: block;
}

.all .option:hover .inner_box {
  border-radius: 3px;
  background: rgba(94, 110, 120, 0.4);
  width: 100%;
  height: 100%;
}

.p_option:hover {
  background: rgba(94, 110, 120, 0.2) !important;
  color: #5e6e78 !important;
}

.input_option_design {
  width: 50px;
  border: none;
  /* display: none; */
  text-align: center;
}

.hover_items {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.click_box {
  border-radius: 19px;
  background: rgba(94, 110, 120, 0.2);
  padding: 5px 8px;
  color: #5e6e78;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
}

.center_section_text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.ppppp {
  display: none;
}

.i_d_btn {
  display: none;
  padding: 0;
}

// .i_d_btn div {
//     height: 7px;
// }

.all .option:hover .ppppp {
  display: block;
}

.all .option:hover .i_d_btn {
  display: flex;
  gap: 2px;
  flex-direction: column;
  align-items: center !important;
  padding: 0;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 8px;
  height: 30px !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(94, 110, 120, 0.4);
  border-radius: 4px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: white;
}

.scroll_bar {
  position: absolute;
  z-index: 99999999999999;
  background: #ffffff;
  width: 100%;
  // padding: 30px !important;
  margin: 0 !important;
  left: -1px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow-y: hidden !important; /* Disable scrolling */  
}

.scrol_bar_3 {
  overflow-y: scroll !important;
  max-height: 150px;
  overflow-x: hidden;
}

.without_border_component {
  width: 100%;
  left: 0 !important;
  position: absolute !important;
  z-index: 99999999999999 !important;
  background: #ffffff;
  padding: 30px !important;
  margin: 0 !important;
  left: -2px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* CSS */
.grey-svg {
  fill: #5e6e78;
  fill-opacity: 0.3;
}

.grey-svg:hover {
  fill: #78858e !important;
  fill-opacity: 0.9 !important;
}

.mainContentBox {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .mainContentBox {
    flex-direction: row;
  }

}

.propose-button {
  border: 1px solid rgba(0, 0, 0, 0.20);
  padding: 8px;
  border-radius: 8px;
  margin-top: 15px;
  height: 33px;
  width: 30%;
  font-size: 1rem;
  color: #ffffff;
  background-color: #007bff;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
      background-color: #0056b3;
      transform: scale(1.05);
  }

  &:active {
      background-color: #004494;
  }

  &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
}
.section-feedback {
    margin: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Wysokość sekcji wynosi 100% wysokości ekranu */
  }
  
.form-container {
    perspective: 1000px;
    width: 100%;
    max-width: 700px;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .form-3d {
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s ease;
  }
  
  .form-3d:hover {
    transform: rotateX(0deg) rotateY(0deg);
  }

  .button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center; /* Centers the button horizontally */
  }
  
  .form-group {
    margin-bottom: 15px;
    justify-content: center; /* Centering the button */
}

.form-group-email { 
    width: 50%;
}
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }

  .form-input {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .form-textarea:focus,
  .form-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .form-button {
    width: auto; /* Ensure the button's width is based on its content */
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: auto;
  }
  
  .form-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .form-3d {
      transform: none;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }
  
  @media (max-width: 480px) {
    .form-3d {
      padding: 15px;
    }
  
    .form-textarea,
    .form-input {
      font-size: 14px;
    }
  
    .form-button {
      font-size: 14px;
      padding: 8px;
    }
  }
  
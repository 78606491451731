.box {
    margin: 0.8em auto;
    position: relative;
    background-color: #fff;

}

.input {
    padding: 0 15px;
    width: 100%;
    height: 55px;
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 8px;
    background: transparent;
    transition: all .3s ease;
}

.label {
    padding: 0 10px;
    position: absolute;
    top: 18px;
    left: 10px;
    font-size: 1rem;
    font-weight: normal;
    background-color: #fff;
    color: #999;
    cursor: text;
    z-index: 2;
    transition: all .3s ease;
}

.input:focus + .label {
    font-size: 11px;
    top: -5px;
}


.input:focus + .label {
    color: #2e79f1;
}

.input:focus {
    border-color: #2e79f1;
    outline: 1px solid #2e79f1;
}
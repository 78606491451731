.angle_btn_design {
    background-color: white !important;
    border: none !important;
    /* margin-right: 3px; */
    cursor: pointer;
}

.component_full_six {
    height: auto;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    padding-right: 13px;

    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.073px;
    padding-left: 0px !important;
    height: 103px;
}

.component_full_six .ul_design {
    padding-left: 14px;
}

.component_full_six .heading {
    padding-left: 27px;
    padding-top: 13px !important;
    margin-bottom: 23px;
}

.left-arrow-design {
    color: #5E6E784D;
}

.left-arrow-design:hover {
    color: #5E6E78CC;
}

.ul_design1 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 300px;
    height: 103px; */
}

.ul_design1 li {
    list-style: none;
}

.ul_design1 input {
    max-width: 35px;
    height: 26px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.select_div_design {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    width: 300px;
    flex-shrink: 0;
    text-align: left;
    margin: 10px;
    padding-left: 10px;
    justify-content: space-between;

}

.rotate {
    transform: rotate(180deg);
}

.card_design {
    width: 300px;

}

.card_design li {
    list-style: none;
}

.ul_design {
    display: flex !important;
    padding-left: 0px;
    list-style: none;
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.073px;
    /* 225.612% */
}

.ul_design li {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.073px;
}

.ul_design input {
    max-width: 35px;
    height: 26px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    margin-left: 3px;
    margin-right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.component_icon a {
    color: black;

}

.component_icon a:hover {
    text-decoration: none;

}

.dropdown-menu {
    border-top: 0px;
}




/* ----------------------- */

.component_full_four .heading {
    padding: 9px 13px;
}

.component_full_four .ul_design {
    padding-left: 8px;

}

.component_full_four {
    height: auto;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    padding: 13px;
    // margin: 50px auto;
    // max-width: 283px !important;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-left: 0px !important;
}

.heading {
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    cursor: pointer;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

/* .react_arrow {
    width: 30.417px;
    height: 15.313px;
} */

.options {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    padding: 3px;
    border-radius: 10px;
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 15px;
}

.options p {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* ======================= */
.projects {
  padding: 7rem 2rem 2rem 2rem;

  @media (max-width: 768px) {
    padding: 7rem 1rem 1rem 1rem;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    width: 100%;

    @media (max-width: 950px) {
      display: flex;
      flex-direction: column;
    }

    &__left {
      display: flex;
      flex-direction: column;

      p {
        color: gray;
        font-size: 0.9rem;
      }

      h3 {
        margin-top: 0.2rem;
      }
    }

    &__right {
      margin-top: 0.2rem;

      @media (max-width: 950px) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      &__skillerButton {
        margin-right: 1rem !important;
        display: none !important;

        @media (max-width: 900px) {
          display: block !important;
        }
      }

      &__filterButton {
        text-transform: capitalize !important;
        background-color: $BUTTON_BLUE !important;

        &__icon {
          font-size: 1rem !important;
        }
      }
    }
  }

  &__body {
    margin-top: 1rem;

    &__filterTabs {
      border-radius: 0.8rem;
      margin-bottom: 1rem;
      background-color: white;
      box-shadow: $BOX_SHADOW;
    }

    &__container {
      display: flex;
      align-items: flex-start;

      @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
      }

      &__left {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        transition: width 0.5s;

        &__card {
          margin-bottom: 1rem;
          padding: 1rem;
          display: flex;
          flex-direction: column;
          border-radius: 0.8rem;
          background-color: white;
          box-shadow: $BOX_SHADOW;

          &__avatar {
            margin-bottom: .5rem;
          }

          &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__iconsBox {
              display: flex;
              align-items: center;

              &__write {
                border: 1px solid lightgray !important;
                font-size: 0.7rem !important;
                text-transform: capitalize !important;
                color: gray !important;
                margin-right: 0.5rem !important;
                padding: 0.1rem 0rem !important;
                font-weight: 600 !important;

                &__icon {
                  font-size: 0.8rem !important;
                  margin-left: 0.2rem;
                  color: gray !important;
                  font-weight: 600 !important;
                }
              }

              &__add {
                border: 1px solid $BUTTON_BLUE !important;
                font-size: 0.7rem !important;
                text-transform: capitalize !important;
                color: $BUTTON_BLUE !important;
                padding: 0.1rem 0rem !important;
                font-weight: 600 !important;


                &__icon {
                  font-size: 0.8rem !important;
                  margin-left: 0.2rem;
                  color: $BUTTON_BLUE !important;
                  font-weight: 600 !important;
                }
              }

              &__edit {
                border: 1px solid $BUTTON_BLUE !important;
                font-size: 0.7rem !important;
                text-transform: capitalize !important;
                color: $BUTTON_BLUE !important;
                padding: 0.1rem 0rem !important;
                font-weight: 600 !important;
                margin-right: .5rem !important;
              }

              &__delete {
                border: 1px solid $BUTTON_BLUE !important;
                font-size: 0.7rem !important;
                text-transform: capitalize !important;
                color: red !important;
                padding: 0.1rem 0rem !important;
                font-weight: 600 !important;
                border: 1px solid red !important;

                &__deleteIcon {
                  font-size: 0.8rem !important;
                  margin-left: 0.2rem;
                  color: red !important;
                  font-weight: 600 !important;
                }
              }
            }
          }

          &__body {
            margin-top: 0.5rem;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;

            &__specs {
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;

              p {
                font-size: 0.8rem;
                text-transform: uppercase;
              }

              &__desc {
                margin-top: 0.3rem;

                span {
                  background-color: $BG_GRAY;
                  border-radius: 0.5rem;
                  padding: 0.1rem 0.5rem;
                  font-size: 0.7rem;
                  font-weight: 600;
                  margin-right: 0.3rem;
                  color: gray;
                }
              }
            }
          }

          &__body2 {
            margin-top: 0.5rem;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            border-top: 1px solid lightgray;
            padding-top: 1rem;

            &__specs {
              // margin-right: 1rem;
              margin-bottom: 1rem;
              

              p {
                font-size: 0.8rem;
                text-transform: uppercase;
              }

              &__desc {
                margin-top: 0.3rem;

                span {
                  background-color: $BG_GRAY;
                  border-radius: 0.5rem;
                  padding: 0.1rem 0.5rem;
                  font-size: 0.7rem;
                  font-weight: 600;
                  margin-right: 0.3rem;
                  color: gray;
                }
              }
            }
          }

          &__bottom {
            border-top: 1px solid lightgray;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__left {
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              &__tip {
                display: flex;
                align-items: center;
                font-size: 0.7rem;
                font-weight: 600;
                margin-right: 0.5rem;
                padding-top: 0.5rem;

                &__icon {
                  color: $YELLOW !important;
                  font-size: 1.2rem !important;
                  margin-right: 0.3rem;
                }

                &__iconAvatar {
                  font-size: .7rem !important;
                  margin-right: 0.3rem;
                  width: 1.2rem !important;
                  height: 1.2rem !important;
                }
              }

              &__type {
                font-size: 0.7rem;
                color: $YELLOW;
                background-color: $PINK;
                border-radius: 0.5rem;
                margin-right: 0.5rem;
                padding: 0.1rem 0.5rem;
                margin-top: 0.5rem;
              }

              &__dot {
                font-size: 1rem;
                color: gray;
                font-weight: 600;
                margin-right: 0.5rem;
                margin-top: -0.5rem;
                padding-top: 0.5rem;
              }

              &__time {
                font-size: 0.7rem;
                color: gray;
                font-weight: 500;
                margin-right: 0.5rem;
                padding-top: 0.5rem;
              }
            }

            button {
              margin-top: 0.5rem;
              color: gray;
              border: 1px solid lightgray;
              font-size: 0.7rem;
              padding: 0px 3px;
              background-color: white;
              border-radius: 5px;
              cursor: pointer;

              &:hover {
                background-color: $BG_COLOR;
              }
            }
          }
        }
      }

      &__right {
        margin-left: 1rem;
        transition: width 0.5s;

        @media (max-width: 900px) {
          display: none;
          margin-left: unset;
          width: 100%;
        }

        &__card {
          width: 250px;
          padding: 1rem;
          border-radius: 0.8rem;
          background-color: white;
          box-shadow: $BOX_SHADOW;

          @media (max-width: 900px) {
            width: 100%;
            border-radius: unset;
          }

          &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;

            &__button {
              border: 1px solid $BUTTON_BLUE !important;
              font-size: 0.7rem !important;
              text-transform: capitalize !important;
              color: $BUTTON_BLUE !important;
              padding: 0.1rem 0.5rem !important;
              font-weight: 600 !important;

              &__icon {
                font-size: 0.8rem !important;
                margin-left: 0.2rem;
                color: $BUTTON_BLUE !important;
                font-weight: 600 !important;
              }
            }
          }

          &__box {
            background-color: $BG_COLOR;
            margin: 0.5rem 0;
            padding: 1rem 0.5rem;
            border-radius: 0.5rem;
            display: flex;
            align-items: center;

            &__icon {
              color: $BUTTON_BLUE;
              margin-right: 0.5rem;
            }

            p {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

.addProject {
  padding: 7rem 2rem 2rem 2rem;
  transition: padding .5s, padding-left .5s, padding-right .5s;
  padding-left: 20%;
  padding-right: 20%;

  @media (max-width: 1250px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 1000px) {
    padding-left: unset;
    padding-right: unset;
    padding: 7rem 1rem 1rem 1rem;
  }

  &__header {
    &__backIcon {
      margin-left: -0.5rem !important;
      color: black !important;
    }
  }

  &__container {
    margin-top: 3rem;

    &__name {
      padding: 0rem 1rem 1rem 1rem;
      border: 1px solid lightgray;
      border-radius: 0.5rem;
      margin-bottom: 1rem;

      p {
        font-weight: 600;
        font-size: 0.7rem;
        color: gray;
        position: absolute;
        margin-top: -0.5rem;
      }

      &__textField {
        margin-top: 1rem;

        input {
          width: 100%;
          border: 1px solid lightgray;
          outline: unset;
          padding: .7rem .5rem;
          border-radius: 5px;
        }
      }
    }

    &__areaExp {
      padding: 0rem 1rem 1rem 1rem;
      border: 1px solid lightgray;
      border-radius: 0.5rem;

      p {
        font-weight: 600;
        font-size: 0.7rem;
        color: gray;
        position: absolute;
        margin-top: -0.5rem;
      }

      &__textField {
        margin-top: 1rem;
      }
    }

    &__totalExp {
      padding: 0 1rem 1rem 1rem;
      border: 1px solid lightgray;
      border-radius: 0.5rem;
      margin-top: 1rem;

      &__head {
        font-weight: 600;
        font-size: 0.7rem;
        color: gray;
        position: absolute;
        margin-top: -0.5rem;

      }

      &__main {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: 768px) {
          display: unset;
          justify-content: unset;
          align-items: unset;
        }

        &__box {
          display: flex;
          justify-content: center;
          align-items: flex-start;

          @media (max-width: 950px) {
            display: flex;
            justify-content: flex-start;
            margin-top: unset;
          }

          @media (max-width: 768px) {
            flex-wrap: wrap;
          }

          &__total {
            width: 32%;
            margin-right: 2rem;
            margin-top: 0.3rem;

            @media (max-width: 768px) {
              width: 100%;
              margin-right: unset;
            }
          }

          &__years {
            width: 32%;
            margin-right: 2rem;
            margin-top: 0.3rem;

            @media (max-width: 768px) {
              width: 100%;
              margin-right: unset;
            }
          }

          &__period {
            width: 32%;
            margin-right: 2rem;
            margin-top: 0.3rem;

            @media (max-width: 768px) {
              width: 100%;
              margin-right: unset;
            }
          }

          &__productType {
            width: 230px;
            margin-top: 0.3rem;


            @media (max-width: 950px) {
              width: 45%;
            }

            @media (max-width: 768px) {
              width: 100%;
            }
          }

          &__removeButton {
            margin-top: 1.5rem;
            margin-left: .5rem;

            @media (max-width: 950px) {
              width: 4%;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
            }

            @media (max-width: 768px) {
              margin-top: 0;
              width: 100%;
              margin-right: unset;
            }

            &__button {
              font-size: .7rem;
              text-transform: capitalize !important;
              color: red;
              margin-top: .3rem;

              &__icon {
                color: red;
              }
            }
          }
        }
      }

      &__addButton {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        &__button {
          color: $BUTTON_BLUE !important;
          border: 1px solid $BUTTON_BLUE !important;
          border-radius: 1rem !important;
          text-transform: capitalize !important;
          font-weight: 600 !important;
          width: 900px;

          &__icon {
            font-size: 1.2rem !important;
            margin-right: 0.5rem;
          }
        }
      }
    }

    &__offeredGratification {
      margin-top: 3rem;

      &__top {
        border-top: 1px solid lightgray;

        span {
          font-weight: 600;
          font-size: 0.7rem;
          color: gray;
          position: absolute;
          background-color: $BG_COLOR;
          margin-top: -0.5rem;
          margin-left: 1rem;
        }
      }

      &__body {
        margin: 2rem 1rem 0 1rem;
        display: flex;
        justify-content: center;

        &__left {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 500px;

          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
          }

          label {
            font-weight: 600;
            font-size: 0.7rem;
            color: gray;
            margin-right: 1rem;

            @media (max-width: 768px) {
              margin-right: unset;
              margin-top: 0.5rem;
            }
          }

          &__input {
            margin-right: 1rem;
            width: 150px;

            @media (max-width: 768px) {
              margin-right: unset;
              width: 100%;
            }

            &:nth-last-child(1) {
              margin-right: 0;
            }
          }
        }
      }
    }

    &__wantedAvaiablility {
      margin-top: 3rem;

      &__top {
        border-top: 1px solid lightgray;

        span {
          font-weight: 600;
          font-size: 0.7rem;
          color: gray;
          position: absolute;
          background-color: $BG_COLOR;
          margin-top: -0.5rem;
          margin-left: 1rem;
        }
      }

      &__body {
        margin: 2rem 1rem 0 1rem;
        display: flex;
        justify-content: center;

        &__left {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 900px;

          @media (max-width: 950px) {
            width: 100%;
          }

          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
          }

          label {
            font-weight: 600;
            font-size: 0.7rem;
            color: gray;
            margin-right: 1rem;

            @media (max-width: 768px) {
              margin-right: unset;
              margin-top: 0.5rem;
            }
          }

          &__input {
            margin-right: 1rem;
            width: 120px;

            @media (max-width: 768px) {
              margin-right: unset;
              width: 100%;
            }

            &:nth-last-child(1) {
              margin-right: 0;
            }
          }

          &__checkBoxes {
            display: flex;
            white-space: nowrap;
            align-items: center;

            @media (max-width: 768px) {
              margin-top: 1rem;
            }

            label {
              margin-right: 0;
            }

            div {
              display: flex;
              align-items: center;
              margin-left: 3rem;

              @media (max-width: 768px) {
                margin-top: 0.5rem;
                margin-left: 0;

                &:nth-last-child(1) {
                  margin-left: 3rem;
                }
              }

              input {
                margin-right: 0.5rem;
                width: 1.2rem;
                height: 1.2rem;
              }

              p {
                font-size: 0.7rem;
                font-weight: 600;
                color: gray;
              }
            }
          }
        }
      }
    }

    &__workingType {
      margin-top: 3rem;

      &__top {
        border-top: 1px solid lightgray;

        span {
          font-weight: 600;
          font-size: 0.7rem;
          color: gray;
          position: absolute;
          background-color: $BG_COLOR;
          margin-top: -0.5rem;
          margin-left: 1rem;
        }
      }

      &__body {
        margin: 2rem 1rem 0 1rem;
        display: flex;
        justify-content: center;

        &__left {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 900px;

          @media (max-width: 950px) {
            width: 100%;
          }

          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            width: 100%;
          }

          label {
            font-weight: 600;
            font-size: 0.7rem;
            color: gray;
            margin-right: 1rem;

            @media (max-width: 768px) {
              margin-right: unset;
              margin-top: 0.5rem;
            }
          }

          &__input {
            margin-right: 1rem;
            width: 120px;

            @media (max-width: 768px) {
              margin-right: unset;
              width: 100%;
            }

            &:nth-last-child(1) {
              margin-right: 0;
            }
          }

          &__checkBoxes {
            display: flex;
            white-space: nowrap;
            align-items: center;

            @media (max-width: 768px) {
              margin-top: 1rem;
            }

            label {
              margin-right: 0;
            }

            div {
              display: flex;
              align-items: center;
              margin-left: 3rem;

              @media (max-width: 768px) {
                margin-top: 0.5rem;
                margin-left: 0;

                &:nth-last-child(1) {
                  margin-left: 3rem;
                }
              }

              input {
                margin-right: 0.5rem;
                width: 1.2rem;
                height: 1.2rem;
              }

              p {
                font-size: 0.7rem;
                font-weight: 600;
                color: gray;
              }
            }
          }
        }
      }
    }

    &__projectDetails {
      margin-top: 3rem;

      &__top {
        border-top: 1px solid lightgray;

        span {
          font-weight: 600;
          font-size: 0.7rem;
          color: gray;
          position: absolute;
          background-color: $BG_COLOR;
          margin-top: -0.5rem;
          margin-left: 1rem;
        }
      }

      &__body {
        margin: 2rem 1rem 0 1rem;
        display: flex;
        justify-content: center;

        &__box {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 900px;

          @media (max-width: 950px) {
            width: 100%;
          }

          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
          }

          &__left {
            display: flex;
            flex-direction: column;
            width: 48%;

            @media (max-width: 768px) {
              width: 100%;
            }

            &__input {
              width: 100%;
              margin-bottom: 2rem;

              &:nth-last-child(1) {
                label {
                  display: flex;
                  flex-direction: column;

                  span {
                    margin-top: 0.3rem;
                    width: 35px;
                  }
                }
              }

              &__sliderFooter {
                display: flex;
                justify-content: space-between;
                width: 100%;

                &__label {
                  font-size: 0.7rem;
                  color: gray;
                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  &__input {
                    display: flex;
                    align-items: center;

                    input {
                      outline: none;
                      border: 1px solid lightgray;
                      background-color: white;
                      padding: 3px 5px;
                      border-radius: 5px;
                      width: 23px;
                      margin: 0 0.2rem;
                    }

                    span {
                      border: 1px solid lightgray;
                      background-color: white;
                      border-radius: 5px;
                      padding: 0 3px;
                      margin: 0 .2rem;
                    }
                  }
                }
              }

              &__inputText {
                display: flex;
                flex-direction: column;

                label {
                  font-size: 0.7rem;
                  color: gray;
                  font-weight: 600;
                  margin-bottom: 0.3rem;
                }

                input {
                  border-radius: 3px;
                  border: 1px solid lightgray;
                  outline: $BUTTON_BLUE !important;
                  padding: 0.65rem 0.5rem;

                  &::placeholder {
                    font-size: 12px;
                  }
                }
              }
            }
          }

          &__right {
            display: flex;
            flex-direction: column;
            width: 48%;

            @media (max-width: 768px) {
              width: 100%;
            }

            &__input {
              margin-bottom: 2rem;
              width: 100%;

              &__textarea {
                display: flex;
                flex-direction: column;

                label {
                  font-size: 0.7rem;
                  color: gray;
                  font-weight: 600;
                  margin-bottom: 0.3rem;
                }

                textarea {
                  border-radius: 3px;
                  border: 1px solid lightgray;
                  outline: $BUTTON_BLUE !important;
                  padding: 0.5rem;

                  &::placeholder {
                    font-size: 12px;
                  }
                }
              }
            }
          }

          &__skillBox {
            width: 100%;

            &__textFields {
              display: flex;
              justify-content: space-between;

              @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
              }

              &__left {
                width: 48%;

                @media (max-width: 768px) {
                  width: 100%;
                }

                &__input {
                  width: 100%;
                  margin-bottom: 1rem;

                }
              }

              &__right {
                width: 48%;

                @media (max-width: 768px) {
                  width: 100%;
                }

                &__input {
                  width: 100%;
                  margin-bottom: 1rem;

                }
              }
            }

            &__slider {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;


              &__label {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                margin-top: 1rem;
                margin-bottom: .5rem;

                label {
                  font-size: .7rem;
                  color: gray;
                  font-weight: 800;
                }
              }

              &__input {
                width: 50%;

                @media (max-width: 768px) {
                  width: 100%;
                }

                &:nth-last-child(1) {

                  label {
                    display: flex;
                    flex-direction: column;

                    span {
                      margin-top: 0.3rem;
                      width: 35px;
                    }
                  }
                }

                &__sliderFooter {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;

                  &__label {
                    font-size: 0.7rem;
                    color: gray;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &__input {
                      display: flex;
                      align-items: center;

                      input {
                        outline: none;
                        border: 1px solid lightgray;
                        background-color: white;
                        padding: 3px 5px;
                        border-radius: 5px;
                        width: 23px;
                        margin: 0 0.2rem;
                      }

                      span {
                        border: 1px solid lightgray;
                        background-color: white;
                        border-radius: 5px;
                        padding: 0 3px;
                        margin: 0 .2rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }


    &__offerButton {
      display: flex;
      justify-content: center;
      align-items: center;

      Button {
        text-transform: capitalize;
        background-color: $BUTTON_BLUE !important;

        @media (max-width: 768px) {
          width: 100% !important;
          margin: 0 1rem !important;
        }
      }
    }
  }
}

.applyFilters {
  transition: padding .5s, padding-left .5s, padding-right .5s;
  padding: 1rem 2rem 2rem 1rem;
  padding-left: 20%;
  padding-right: 20%;

  @media (max-width: 1250px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 1000px) {
    padding-left: unset;
    padding-right: unset;
    padding: 1rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__buttons {
      display: flex;
      align-items: center;

      &__cancel {
        border-color: $BUTTON_BLUE !important;
        text-transform: capitalize !important;
        margin-right: 1rem !important;
      }

      &__apply {
        background-color: $BUTTON_BLUE !important;
      }
    }
  }

  &__container {
    margin-top: 1rem;

    &__box {
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      &__contentBox {
        border: 1px solid lightgray;
        border-radius: 0.7rem;
        margin-top: 1rem;
        padding: 0 1rem 1rem 1rem;
        width: 49%;

        @media (max-width: 768px) {
          width: 100%;
        }

        &__label {
          font-size: 0.7rem;
          font-weight: 600;
          color: gray;
          position: absolute;
          margin-top: -0.5rem;
          background-color: white;
        }

        &__locationLanguage {
          margin-top: 0.7rem;
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          justify-content: space-between;

          &__textField {
            width: 49%;
            margin-right: 0.5rem;

            &:nth-last-child(1) {
              margin-right: 0;
            }
          }
        }

        &__gratification {
          margin-top: 0.7rem;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;

          &__textField {
            width: 150px;
            max-width: 30%;
            margin-right: 0.5rem;

            &:nth-last-child(1) {
              margin-right: 0;
            }
          }

          &__for {
            font-size: 0.7rem;
            font-weight: 600;
            color: gray;
            margin-inline-end: 0.5rem;
          }

          p {
            color: gray;
            font-size: 0.7rem;
            white-space: nowrap;

            @media (max-width: 600px) {
              white-space: unset;
            }
          }
        }

        &__projectType {
          margin-top: 1rem;

          &__slider {
            margin-top: -0.5rem;

            &__sliderFooter {
              display: flex;
              justify-content: space-between;
              margin-top: -0.8rem;

              p {
                color: gray;
                font-size: 0.7rem;

                input {
                  border: 1px solid lightgray;
                  border-radius: 5px;
                  padding: 3px;
                  margin: 0 0.3rem;
                  width: 23px;
                  outline: none;
                }

                span {
                  border: 1px solid lightgray;
                  margin: 0 .2rem;
                  padding: 0rem .2rem;
                  border-radius: 5px;
                }
              }
            }
          }
        }

        &__workingType {
          margin-top: 2.25rem;
          display: flex;
          align-items: center;
          justify-content: center;

          &__textField {
            width: 6rem;
            display: flex;
            align-items: center;

            input {
              width: 1rem;
              height: 1rem;
            }

            span {
              font-weight: 600;
              font-size: 0.7rem;
              color: gray;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.addedSkillerSidebar {
  &__button {
    text-transform: capitalize !important;
    background-color: $BUTTON_BLUE !important;
  }
}

.clicked-button {
  background-color: white !important;
  color: $BUTTON_BLUE !important;
}

.cSelect__label {
  white-space: nowrap;
}
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  padding-top: 7rem;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    width: 80%;

    @media (max-width: 1200px) {
      width: 90%;
    }

    @media (max-width: 1000px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
    }

    &__left {
      padding-right: 2rem;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 3rem;
        text-align: center;
      }

      p {
        font-weight: 600;
      }

      h3 {
        margin-top: .2rem;
        font-size: 2rem;
        margin-bottom: .5rem;

        b {
          font-weight: 800;
        }
      }

      span {
        font-size: .8rem;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        width: 100%;
      }

      &__card {
        display: flex;
        align-items: center;
        border-radius: 0.8rem;
        background-color: white;
        box-shadow: $BOX_SHADOW;
        margin: .5rem 0;
        width: 350px;
        text-decoration: unset;
        
        @media (max-width: 768px) {
          width: 100%;
        }

        &__iconBox {
          margin-right: 1rem;
          margin-left: 1rem;

          &__icon {
            color: lightgray;
          }
        }

        &__details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          &__text {
            display: flex;
            flex-direction: column;
            padding: .3rem 0;
            color: black;

            p {
              font-weight: 600;
            }

            span {
              font-size: .8rem;
              margin-top: .3rem;
            }
          }

          &__forwardIcon1 {
            background-color: $ORANGE_LIGHT;
          }

          &__forwardIcon {
            width: 45px;
            height: 85px;
            border-radius: 0 .8rem .8rem 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &__icon1 {
              color: $YELLOW;
              background-color: $ORANGE_LIGHT;
            }

            &__icon {
              font-size: 1rem !important;
              color: white;
            }
          }
        }
      }

      &__card1 {
        border: 2px solid $ORANGE;

        &__iconBox {
          &__icon1 {
            color: $YELLOW;
          }
        }

        &__details {

          &__forwardIcon1 {
            background-color: $ORANGE_LIGHT;

            &__icon1 {
              color: $YELLOW;
              background-color: $ORANGE_LIGHT;
            }
          }
        }
      }

      &__card2 {
        border: 2px solid $BUTTON_BLUE;

        &__iconBox {
          &__icon2 {
            color: $BUTTON_BLUE;
          }
        }

        &__details {

          &__forwardIcon2 {
            background-color: $BLUE_LIGHT;

            &__icon2 {
              color: $BUTTON_BLUE;
              background-color: $BLUE_LIGHT;
            }
          }
        }
      }

      &__card3 {
        border: 2px solid $PURPLE;

        &__iconBox {
          &__icon3 {
            color: $PURPLE;
          }
        }

        &__details {

          &__forwardIcon3 {
            background-color: $PURPLE_LIGHT;

            &__icon3 {
              color: $PURPLE;
              background-color: $PURPLE_LIGHT;
            }
          }
        }
      }

      &__card4 {
        border: 2px solid $PINK2;

        &__iconBox {
          &__icon4 {
            color: $PINK2;
          }
        }

        &__details {

          &__forwardIcon4 {
            background-color: $PINK2_LIGHT;

            &__icon4 {
              color: $PINK2;
              background-color: $PINK2_LIGHT;
            }
          }
        }
      }
    }
  }
}

.homeCardsView {
  padding: 7rem 2rem 2rem 2rem;

  @media (max-width: 768px) {
  padding: 7rem 1rem 1rem 1rem;  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__headingAccount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      margin-top: 2rem;

      span {
        font-size: .8rem;
        margin-top: .5rem;
      }
    }

    &__backIcon {
      &__icon {
        color: black !important;
      }
    }

    p {
      font-weight: 800;
      margin-left: .5rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    &__projectCards {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 5rem 0;

      @media (max-width: 768px) {
        margin: 1rem;
      }

      &__card {
        margin: 1rem;
        background-color: white;
        border-radius: .8rem;
        padding: 6rem 1rem 3rem 1rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        

        @media (max-width: 768px) {
          margin: 1rem 0.5rem;
        }

        @media (max-width: 500px) {
          width: 100%;
          margin: 1rem 0;
        }

        &__iconBox {
          &__icon {
            font-size: 3rem !important;
            color: gray;
          }
        }

        p {
          font-weight: 800;
          margin-top: 2rem;
          color: black;
          font-size: .9rem;
        }

        span {
          font-size: .8rem;
          margin-top: .3rem;
        }
      }

      &__cardProject {
        border: 2px solid $YELLOW;

        &__iconBox {
          &__iconProject {
            font-size: 3rem !important;
            color: $YELLOW !important;
          }
        }
      }

      &__cardSkill {
        border: 2px solid $PURPLE;

        &__iconBox {
          &__iconSkill {
            font-size: 3rem !important;
            color: $PURPLE !important;
          }
        }
      }

      &__cardAccount {
        border: 2px solid $BUTTON_BLUE;

        &__iconBox {
          &__iconAccount {
            color: $BUTTON_BLUE !important;
            font-size: 3rem !important;
          }
        }
      }
    }

    &__continueButton {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
      }

      Button {
        text-transform: capitalize;
        background-color: $BUTTON_BLUE !important;

        @media (max-width: 500px) {
          width: 100%;
          margin: 0 1rem;
        }
      }
    }
  }
}
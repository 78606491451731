.sidebar {
  background-color: white;
  border-radius: .8rem;
  box-shadow: $BOX_SHADOW;
  padding: 1rem;
  width: 180px;
  min-height: 76vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;

  @media (max-width: 768px) {
    height: 100%;
    min-height: 100vh;
    position: unset;
  }

  &__links {
    display: flex;
    flex-direction: column;

    &__link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: .3rem 0;
      border-radius: 5px;
      padding: .7rem .8rem;
      background-color: $LIGHT_GRAY;
      white-space: nowrap;
      color: black;
      text-decoration: unset;
      font-weight: 600;
      font-size: .7rem;
      white-space: nowrap;

      &__icon {
        color: $BUTTON_BLUE;
        font-size: 1rem !important;
        margin-right: .5rem;
      }
    }
  }
}

.sidebar-button-clicked {
  border: 1px solid $BUTTON_BLUE !important;
  background-color: $BLUE_LIGHT !important;
}
.projects__body__container__left__card {
    margin-bottom: 1rem;
    // padding: 2rem;
    display: flex;
    flex-direction: column;
    // align-items: stretch;
    // gap: 1.5 rem;
    border-radius: 0.8rem;
    background-color: white;
    box-shadow: 0 0.5rem 1.5rem #d3d3d3;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1577px !important;
}

.filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
}

.filter-box-container {
    width: 20%;
    border: 1px solid rgba(78, 69, 69, 0.2);
    border-radius: 10px;
}

.filter-box-container.selected {
    width: 20%;
   border: 1px solid #337eff68;
   border-radius: 10px;
   box-shadow: 0 0 5px 2px #6a9df6;  /* Increased spread for more visible glow */
   transition: border-color 0.3s ease, box-shadow 0.3s ease; 
}


@media (min-width: 769px) and (max-width: 1200px) {
    .filter-box-container {
        width: 40% !important;
    }

}


.search-bar-container {
    display: flex;
    align-items: center;
    gap: 10px;                    // Space between input and button
    margin-bottom: 25px;          // Space below the search bar
    width: 80% !important;        // Match width of filter boxes
    max-width: 1250px;            // Align with filter width, preventing excessive stretching
}

.search-bar-container input {
    flex: 1;                      // Input takes remaining space
    padding: 1rem;
    border: 1px solid #d3d3d3;    // Light border for input
    border-radius: 0.5rem;
    font-size: 1rem;
    outline: none;                // Remove default focus outline
    transition: border-color 0.3s ease;
}

.search-bar-container input:focus {
    border-color: #327FFF;        // Highlight border on focus
    box-shadow: 0 0 5px rgba(50, 127, 255, 0.5);  // Glow effect
}

.search-bar-container button {
    flex: 0 0 auto;               // Prevent button from shrinking
    height: 50px;
    border-radius: 0.5rem;
    background-color: #327FFF;    // Base color for button
    color: white;
    border: none;
    cursor: pointer;
    padding: 0 20px;
    font-weight: bold !important;
    text-transform: uppercase;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.search-bar-container button:hover {
    background-color: #3974d4 !important;  // Darker blue on hover
    box-shadow: 0 0 10px rgba(50, 127, 255, 0.5);  // Add subtle shadow
}

.search-bar-container button:active {
    background-color: #285eb8;    // Even darker on click
    box-shadow: none;
}



.filter_buttons {
    display: flex;
    justify-content: end;
    gap: 25px;
    margin-top: 25px;
}

.filter-box-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 12.8px;
}

.filter-box {
    margin-top: 0.5rem;
    padding: 1rem;
    border: 1px solid #d3d3d3;
    border-radius: 0.5rem;
    background-color: white;
    transition: background-color 0.3s, color 0.3s;
}

/* Add styling for active state */
.filter-box:checked {
    background-color: #3498db;
    color: white;
}

@media (max-width:768px) {
    .filter_buttons {
        justify-content: center !important;
    }

    .filter-box-container {
        width: 100% !important;
    }
}

.apply_button {
    background-color: #327FFF !important;
    margin: 10px;
    text-align: center;
    text-transform: uppercase;
    background-size: 200% auto;
    color: white !important;
    border-radius: 80px !important;
    display: block;
    width: 180px;
    height: 50px;
    border: none;
    font-weight: bold !important;
    cursor: pointer;
}

.apply_button:hover {
    background-color: #3974d4 !important;
}

.clear_filters {
    font-weight: bold !important;
    background-color: #cde9fe !important;  // Light blue initial state
    margin: 10px;
    text-align: center;
    text-transform: uppercase;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    background-size: 200% auto;
    color: black !important;
    box-shadow: 0 0 20px #eee;
    border-radius: 80px !important;
    display: block;
    width: 180px;
    height: 50px;
    border: none;
    cursor: pointer;
}

.clear_filters:hover {
    background-color: #9ccff6 !important;  // Slightly darker shade of blue on hover
    color: #fff !important;                // White text on hover
    text-decoration: none;
}

.clear_filters:active {
    background-color: #a6cde3 !important;  // Slightly darker blue on click
    box-shadow: none;
}

 
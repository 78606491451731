.nofound-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #343a40;
    text-align: center;
    font-family: 'Arial', sans-serif;

    .message {
        max-width: 600px;

        h1 {
            font-size: 3rem;
            margin-bottom: 20px;
        }

        p {
            font-size: 1.25rem;
            margin-bottom: 20px;
        }

        a {
            display: inline-block;
            padding: 10px 20px;
            font-size: 1rem;
            color: #ffffff;
            background-color: #007bff;
            border-radius: 5px;
            text-decoration: none;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
}
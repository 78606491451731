._header__box {
  display: flex; // Align items in a single line
  align-items: left; // Center items vertically
  // justify-content: space-between; // Space elements evenly
  gap: 1em; // Add space between items
  padding: 0 2em; // Optional: Add padding for better spacing
}

._titles__box {
  display: fixed; // Use flexbox
  flex-direction: column; // Stack items vertically
  align-items: flex-start; // Align items to the left
  gap: 0.5em; // Add spacing between elements (optional)
  padding-right: 3em; // Optional: Add padding to the container
  
}

._usernameWrapper {
  display: inline-block; // Ensure the wrapper takes up the correct area
  padding: 0.2em 0.5em; // Add padding around the link
  cursor: pointer; // Show pointer to indicate it's clickable

}

._usernameLink {
  text-decoration: none; // Remove underline from the text
  color: #007bff; // Set the color of the text
  opacity: 70%;

  &:hover {
    opacity: 100%;
  }
}




._box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: .1em 1em 2em 1em;
  padding: 2.5em 3em;
  // background-color: white;
  border-radius: 12px;
  // border: 1px solid rgba(0, 0, 0, 0.4);
  // box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);



  &__title {
    padding: 1em 1.2em;
    position: relative;
    font-size: 2rem;
    z-index: 0;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background: rgba(46, 121, 241, 0.35);
      // border-radius: 5px;
      // backdrop-filter: blur(0.3px);
      // -webkit-backdrop-filter: blur(0.7px);
      // border: 1px solid rgba(46, 121, 241, 0.49);
      content: "";
    }
  }

  &__creator {
    margin-top: .5em;
    font-size: 1.0rem;
  }

  &__headerBox {
    margin-top: 1em;
    align-self: space-around;
  }

  &__heartBtn,
  &__chatBtn {
    position: relative;
    // margin: 0 0.5em;
    padding: 1em 1em;
    border: none;
    // border-radius: 10%;
    background-color: transparent;
    transition: transform 0.3s;
    cursor: pointer;
    opacity: 60%;
    &:hover {
      transform: scale(1.05);
      opacity:90%;
    }
  }

  &__chatBtn::before, &__heartBtn::before {
    display: none;
    position: absolute;
    top: -2%;
    padding: 0.5em 1em;
    border-radius: 12px;
    background-color:rgba(46, 121, 241, 0.35);
    font-size: 0.7rem;
  }

  &__chatBtn::before {
    content: "Open chat with creator";
    left: 80%;
    width: 140%;
  }
  &__heartBtn::before {
    content: "Add to favourites";
    right: 80%;
  }

  // &__chatBtn:hover::before, &__heartBtn:hover::before {
  //   // display: block;
  // }


  &__heartIcon,
  &__chatIcon {
    font-size: 2.2rem !important;
  }

  &__heartIcon {
    color: red;
  }

  &__chatIcon {
    color: #3380ff;
    transition: color 0.3s;
  }

  &__chatBtn:hover &__chatIcon {
    color: #2e79f1;
  }

  &__infoBox {
    margin: 0 auto;
    margin-top: 1em;
  }

  &__card {
    margin: 1em 0;
    padding: 1em 2em;
    background-color: rgb(252, 252, 252);
    border-radius: 12px;
    box-shadow: 1px 1px 3px rgb(0, 0, 0, 0.3);
  }

  &__cardTitle {
    margin: 0.8em 0;
    font-size: 1.2rem;
  }

  &__cardInfoList {
    list-style: none;
  }

  &__cardInfo {
    margin: 0.4em 0;
    display: flex;
    align-items: center;
  }

  &__cardInfoArrow {
    margin-right: 0.2em;
    color: rgb(46, 121, 241);
    transform: rotate(180deg);
  }
}

.underline {
  margin-top: 1em;
  width: 80%;
  height: 3px;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .box {
    &__infoBox {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1em;
    }
  }
}
@media (min-width: 1200px) {
  .box {
    &__infoBox {
      display: grid; 
      grid-template-columns: repeat(4, 1fr);
      gap: 1em;
    }

    &__title {
      font-size: 2.4rem;
      &::before {
        border-radius: 60px;
      }
    }
  }
}

.pane_container {
  position: relative; // Make this the positioning context for the right pane
  display: flex;
  height: calc(100vh - 90px); // Adjust to the height of the top bar
  overflow: hidden;
  margin-top: 90px; // Push the content below the top bar
}

.pane_mainContent {
  flex-grow: 1; // Allows the main content to grow and use available space
  // padding: 20px;
  overflow-y: auto;
}

.pane_rightPane {
  position: absolute; // Position it absolutely within the pane_container
  top: 0;
  right: 0; // Align it to the right of the container
  width: 30vw; // Width when pane is open
  transition: opacity 2s ease, width 0.3s ease, transform 0.7s ease; // Smooth transitions for open/close
  background: whitesmoke;
  // opacity: 80%;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  border-top-left-radius: 2%; // Round the top-left corner
  border-bottom-left-radius: 2%; // Round the bottom-left corner
  border-top-right-radius: 0; // Ensure the right corners are not rounded
  border-bottom-right-radius: 0;
  opacity: 95%;
  height: 100%; // Fill the height of the container
}

.pane_rightPane.pane_closed {
  width: 20vw; // Keep a part of the pane visible
  transform: translateX(80%); // Move it off-screen
  // opacity: 50%;
}

.pane_rightPane.pane_open {
  width: 40vw; // Full width when open
  transform: translateX(0); // Reset transform to show pane
  // opacity:85%;
}

.pane_paneContent {
  padding: 20px;
  max-height: 94%;
}

.pane_toggleButton {
  margin-left: 1em;
  margin-top: 1em;
  background: transparent;
  color: #327fff;
  border: transparent;
  border-radius: 30%;
  opacity: 100%;
  cursor: pointer;
  // font-size: 2em;
}

.pane_paneContent {
  display: flex;
  flex-direction: column;
  height: 100%; // Use full height of the pane
  padding: 20px;
  box-sizing: border-box; 
}

.pane_title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1em;
  font-weight: bold;
}

.pane_text {
  font-size: 1rem;
  color: #666;
  // margin-bottom: 1.5em;
  line-height: 1.5;
  // flex-grow: 1; // Allow this to grow and take up available space
}

.pane_messageWrapper {
  margin-top: auto; // Pushes this section to the bottom of the pane
  display: flex;
  flex-direction: column;
}

.pane_input {
  width: 100%;
  height: 20vh;
  padding: 0.8em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 0.8rem;
  font-style: normal;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.pane_input:focus {
  border-color: #327fff;
  outline: none;
  box-shadow: 0 0 5px rgba(50, 127, 255, 0.5);
}


.pane_sendButton {
  width: 100%;
  padding: 0.8em;
  background-color: #327fff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pane_sendButton:hover {
  background-color: #1a5fbf;
}

.alert-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  animation: slideIn 0.5s ease-out, slideOut 0.5s ease-in 3s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

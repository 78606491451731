$error-color: #ff4c4c;
$background-color: #f2f2f2;
$text-color: #333;
$font-family: 'Arial', sans-serif;

.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: $text-color;
    font-family: $font-family;
    text-align: center;

    .error-code {
        font-size: 10rem;
        font-weight: bold;
        color: $error-color;
        margin: 0;
    }

    .error-message {
        font-size: 2rem;
        margin: 1rem 0;
    }

    .error-description {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    .back-button {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        color: #fff;
        background-color: $error-color;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            background-color: darken($error-color, 10%);
        }
    }
}
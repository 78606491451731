.noContentBox {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

     &{
        .noContentText {
            margin-bottom: 1rem;
            font-size: 2rem;
        }

        .noContentLink {
            display: block;
            padding: 1rem 1.5rem;
            background-color: #fff;
            text-decoration: none;
        }
     }
}
// BASIC
$BG_COLOR: #F0F8FF;
$BG_GRAY: #EFF0F1;
// OTHER COLORS
$BUTTON_BLUE: #3380FF;
$BLUE_LIGHT: #D6E6FF;
$YELLOW: #FFB849;
$PINK: #FFF8ED;
$ORANGE: #F9D08D;
$ORANGE_LIGHT: #FFF1DB;
$PURPLE: #9181DB;
$PURPLE_LIGHT: #E9E6F8;
$PINK2: #D06D6D;
$PINK2_LIGHT: #FBE7E7;
$BOX_SHADOW: 0 0.5rem 1.5rem #d3d3d3;
$LIGHT_GRAY: #F5F9FF;
.conversations {
  padding: 5rem 2rem 0 2rem;
  margin-bottom: 1rem;

  @media (max-width: 1000px) {
    padding: 7rem 2rem 0 2rem;
  }

  @media (max-width: 768px) {
    padding: 7rem 1rem 0 1rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1000px) {
      margin-bottom: 0.5rem;
    }

    &__sidebar {
      visibility: hidden;

      @media (max-width: 768px) {
        visibility: visible;
      }
    }

    &__people {
      visibility: hidden;

      @media (max-width: 1000px) {
        visibility: visible;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;

    &__sidebar {
      border-radius: .8rem;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &__chat {
      flex: 1;
      margin: 0 1rem;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      min-height: 76vh;
      margin-left: 196px;

      @media (max-width: 1000px) {
        margin-right: 0;
      }

      @media (max-width: 768px) {
        margin: 0;
      }

      &__box {
        background-color: white;
        border-radius: 0.8rem;
        padding: 2rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;

        &__left {
          margin-bottom: 1rem;

          p {
            background-color: $BG_COLOR;
            padding: 0.7rem 1rem;
            font-size: 0.8rem;
            font-weight: 500;
            border-radius: 0 1rem 1rem 1rem;
            width: fit-content;
          }

          span {
            font-size: 0.6rem;
            font-weight: 500;
            margin-left: 1rem;
          }
        }

        &__right {
          margin-bottom: 1rem;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p {
            background-color: $BUTTON_BLUE;
            color: white;
            padding: 0.7rem 1rem;
            font-size: 0.8rem;
            font-weight: 500;
            border-radius: 1rem 0 1rem 1rem;
            width: fit-content;
          }

          span {
            font-size: 0.6rem;
            font-weight: 500;
            margin-right: 1rem;
            margin-top: 0.3rem;
          }
        }
      }

      &__typeBox {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;

        &__type {
          flex: 1;
          background-color: white;
          border-radius: 0.8rem;
          padding: 0.6rem 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__file {
            margin: 0 0.5rem;
          }

          &__input {
            flex: 1;
            outline: none;
            border: none;
          }

          &__modeIcon {
            margin-left: 0 0.8rem;
          }

          &__cameraIcon {
            margin: 0 0.8rem;
          }
        }

        &__sendButton {
          background-color: $BUTTON_BLUE !important;
          margin-left: 0.5rem !important;
          border-radius: 0.8rem !important;
          padding: 0 !important;
        }
      }
    }

    &__people {
      width: 250px;
      border-radius: 0.8rem;
      background-color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      min-height: 100%;

      &__head {
        font-size: 0.8rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      &__card {
        margin: 0.3rem 0;
        display: flex;
        align-items: center;
        background-color: $LIGHT_GRAY;
        padding: 0.5rem;
        border-radius: 5px;

        &__avatar {
          width: 30px !important;
          height: 30px !important;
          font-size: 0.8rem !important;
        }

        &__desc {
          display: flex;
          flex-direction: column;
          margin-left: 0.5rem;
          width: 100%;

          &__name {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              font-weight: 600;
              font-size: 0.7rem;
            }

            span {
              color: gray;
              font-weight: 600;
              font-size: 0.6rem;
            }
          }

          &__previewText {
            font-size: 0.7rem;
            color: gray;
            margin-top: 0.1rem;
          }
        }
      }
    }
  }
}

.peopleBox {
  min-height: 76vh;
  border-radius: .8rem;
  overflow-y: auto;

  @media (max-width: 1000px) {
    display: none;
  }
}

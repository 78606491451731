.date_btn {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    width: 120px;
    height: 25px;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.073px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    display: flex;
    justify-content: center;
}
.date_btn:focus{
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.20);
}

.component_full_seven {
    height: auto;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    padding: 13px;
    // margin: 50px auto;
    max-width: 100%;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.073px;
    padding-left: 12px !important;
}

.component_full_seven .heading {
    padding-left: 27px;
    padding-top: 13px;
}

.component_full .heading {
    padding-left: 27px;
    padding-top: 13px;
}

.component_full .date_container {
    padding: 0px 11px;
}

.date_container {
    display: flex;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.073px;
    /* 193.382% */
    padding: 11px;
    width: 300px !important;
    gap: 3px;
    justify-content: center;
}

.rotate {
    transform: rotate(180deg);
}

.date_container span {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.073px;
}

.calender_design {
    margin-top: 10px;
    // padding-left: 13px;
}

.before_date_text {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.073px;
    /* 193.382% */
}

.dddddd {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    gap: 4px;

}

.dddddd small {
    color: #000;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.073px;
}

.css-1vs7z2v-MuiYearCalendar-root{
    width: 100% !important;
}

.css-kiu2gm{
    width: 100% !important;
}